import * as React from 'react';
import Select from 'react-select';
import { ActionMeta, InputActionMeta } from 'react-select/lib/types';
import { CircularProgress, IconButton, MenuItem, Paper, TextField, Typography, Tooltip } from '@material-ui/core';
import { ControlProps } from 'react-select/lib/components/Control';
import { OptionProps } from 'react-select/lib/components/Option';
import { PlaceholderProps } from 'react-select/lib/components/Placeholder';
import { SingleValueProps } from 'react-select/lib/components/SingleValue';
import { MenuProps } from 'react-select/lib/components/Menu';
import * as Styled from './styled';
import Clear from '@material-ui/icons/Clear';
import { IndicatorProps } from 'react-select/lib/components/indicators';
import { debounce } from 'typescript-debounce-decorator';
import Mutex from '../../api/util';

export interface AutoCompleteProps<T> {
    clearable?: boolean; // Whether to render the clear button
    errored?: boolean;
    errorMessage?: string;
    disabled?: boolean;
    currentItem: T;
    getItemText: (item: T) => string; // function that accepts an item and returns a string to display in the menu
    onClear?: () => void;
    onSelect?: (element?: T) => void;
    onBlur?: () => void;
    fetch: (searchText: string) => Promise<T[] | T>;
    label?: string;
    width?: number | string; // width of field defaults to fill container
    formatItem?: (element: T) => JSX.Element; // custom process for data element overrides getItemText
    tooltip?: (item: T) => string;
}

interface State<T> {
    isLoading: boolean;
    options: T[] | T;
    inputValue: string;
}

// tslint:disable-next-line
function NoOptionsMessage(props: any) {
    return (
        <Typography
            color="secondary"
            style={{padding: '4px 8px'}}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
}

// tslint:disable-next-line
function inputComponent({ inputRef, ...props }: any) {
    return <Styled.InnerInput ref={inputRef} {...props} />;
}

function Placeholder(props: PlaceholderProps<{}>) {
    return (
        <Typography
            color="secondary"
            style={{
                position: 'absolute',
                left: 10,
                fontSize: 16,
            }}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
}

function Menu(props: MenuProps<{}>) {
    return (
        <Paper
            style={{    
                position: 'absolute',
                zIndex: 1,
                marginTop: 4,
                left: 0,
                right: 0,
            }}    
            square={true} 
            {...props.innerProps}
        >
            {props.children}
        </Paper>
    )
}

function LoadingIndicator() {
    return (
        <CircularProgress 
            size={20} 
            thickness={6} 
            style={{
                position: 'relative',
            }}
        />
    );
}

function ClearIndicator(props: IndicatorProps<{}>) {
    const {innerProps: { ref, ...restInnerProps } } = props
    return (
        <Clear 
            {...restInnerProps} 
            innerRef={ref} 
            style={{width: 20, height: 20, padding: 0, cursor: 'pointer'}}
        />
    );
}

const components = {
    NoOptionsMessage,
    Placeholder,
    Menu,
    LoadingIndicator,
    ClearIndicator,
};
export default class AutoCompleteField<T> extends React.Component<AutoCompleteProps<T>, State<T>> {
    
    state = {
        optionsLoaded: false,
        isLoading: false,
        options: [],
        inputValue: ''
    }
    mutex = new Mutex();
    constructor(props: AutoCompleteProps<T>) {
        super(props);
        this.handleLoadOptions = this.handleLoadOptions.bind(this);
    }

    SingleValue = (props: SingleValueProps<{}>) => {
        let value: string | JSX.Element = '';
        if (props.hasValue) {
            if (this.props.formatItem) {
                value = this.props.formatItem((props.getValue() as T[])[0]);
            } else {
                value = this.props.getItemText((props.getValue() as T[])[0]);
            }
        }
        return (
            <Styled.SingleValue style={{fontSize: 16}} {...props.innerProps}>
                {value}
            </Styled.SingleValue>
        );
    }

    /**
     * clear input (i.e. select undefined) if input is empty and user pressed backspace
     */
    handleControlKeyPress = (e: React.KeyboardEvent) => {
        if (this.state.inputValue.length === 0 && e.key === 'Backspace') {
            if (this.props.onSelect) {
                this.props.onSelect(undefined);
            }
        }
    }
    
    Control = (props: ControlProps<{}>) => {
        const { currentItem, tooltip, getItemText } = this.props;
        const toolTipText: string = currentItem ? (tooltip && tooltip(currentItem)) || getItemText(currentItem) : '';

        return (
            <Tooltip title={toolTipText} disableFocusListener={true} >
                <TextField
                    label={this.props.label ? this.props.label : 'Select...'}
                    fullWidth={true}
                    onKeyDown={this.handleControlKeyPress}
                    InputProps={{
                        inputComponent,
                        style: {height: '32px'},
                        inputProps: {
                            innerRef: props.innerRef,
                            children: props.children,
                            ...props.innerProps,
                        },
                    }}
                    InputLabelProps={{
                        shrink: props.hasValue || props.isFocused,
                    }}
                    disabled={props.isDisabled}
                    style={{padding: 0}}
                    error={this.props.errored}
                    helperText={this.props.errored ? this.props.errorMessage : undefined}
                    {...props.selectProps.textFieldProps}
                />
            </Tooltip>
        );
    }

    Option = (props: OptionProps<{}> & { data: T }) => {
        let children = props.children;
        if (this.props.formatItem) {
            children = this.props.formatItem(props.data);
        } else {
            children = this.props.getItemText(props.data);
        }
        let toolTipText: string = (this.props.tooltip && this.props.tooltip(props.data))
            || this.props.getItemText(props.data) || '';
        return (
            
                <MenuItem
                    buttonRef={props.innerRef!}
                    selected={props.isFocused}
                    component="div"
                    title={toolTipText}
                    style={{
                        fontWeight: props.isSelected ? 500 : 400,
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        display: 'block',
                        textOverflow: 'ellipsis'
                    }}
                    {...props.innerProps}
                    onMouseDown={(e) => { if (e.button === 2) { e.preventDefault(); }  }}
                >
                    {children}
                </MenuItem>
        );
    }
    
    onChange = (value: T, {action}: ActionMeta) => {
        switch (action) {
            case 'select-option':
                if (this.props.onSelect) {
                    this.props.onSelect(value);
                }
                break;
            case 'clear':
                if (this.props.onClear) {
                    this.props.onClear();
                }
                break;
            default:
                break;
        }
        
    }
    
    onInputChange = (value: string, {action}: InputActionMeta) => {
        switch (action) {
            case 'input-change': {
                if (this.props.currentItem && this.props.onSelect) {
                    this.props.onSelect(undefined);
                }
                this.setState({inputValue: value});
                this.mutex.execute(async () => {
                    await Promise.all([
                        this.handleLoadOptions()
                    ])
                });
                // this.handleLoadOptions();
                break;
            }
            case 'input-blur': {
                this.setState({inputValue: value});
                break;
            }
            case 'menu-close' || 'set-value': {
                this.setState({inputValue: ''});
                break;
            }
            default:
                break;
        }
        
    }
    
    @debounce(1000, {leading: false})
    async handleLoadOptions () {
        this.setState({ isLoading: true, options: []});
        let options = await this.props.fetch(this.state.inputValue);
        this.setState({isLoading: false, options});
    }
    
    fetchOptions = async () => {
        this.mutex.execute(async () => {
            await Promise.all([
                this.onFocusOptions()
            ])
        });
    }
    
    onFocusOptions = async () => {
        this.setState({ isLoading: true, options: [] });
        let options = await this.props.fetch(this.state.inputValue);
        this.setState({isLoading: false, options});
    }
    
    render() {
        const {
            clearable,
            disabled,
            onBlur,
            currentItem,
            getItemText,
            width
        } = this.props;
        
        return (
                <div style={{width: width}}>
                    <Select
                        isClearable={clearable}
                        isDisabled={disabled}
                        onBlur={onBlur ? onBlur : () => {/** */}}
                        onChange={this.onChange}
                        value={currentItem}
                        menuPortalTarget={document.body}
                        menuShouldBlockScroll={true}
                        inputValue={this.state.inputValue}
                        onInputChange={this.onInputChange}
                        openMenuOnFocus={true}
                        // loadOptions={fetch}
                        isLoading={this.state.isLoading}
                        onFocus={this.fetchOptions}
                        filterOption={() => true}
                        options={this.state.options}
                        getOptionValue={getItemText}
                        tabSelectsValue={false}
                        placeholder={''}
                        noOptionsMessage={() => 'No matches found'}
                        components={{
                            IndicatorSeparator: () => <></>,
                            DropdownIndicator: () => <></>,
                            Control: this.Control,
                            Option: this.Option,
                            SingleValue: this.SingleValue,
                            ...components
                        }}
                        styles={{
                            menuPortal: base => ({
                                ...base,
                                zIndex: 2000
                            }),
                            input: base => ({
                                ...base,
                                '& input': {
                                    font: 'inherit',
                                },
                            }),
                            clearIndicator: base => ({
                                ...base,
                                cursor: 'pointer',
                            }),
                            valueContainer: base => ({
                                ...base,
                                padding: 0
                            })
                        }}
                    />
                </div>
        );
    }
}