import * as React from 'react';
import * as Styled from './styled';
import { TimerChunk } from '../../api/types/types';
import { Checkbox } from '@material-ui/core';
import ImmutableTimer from '../../api/immutables/ImmutableTimer';
import { NarrativeField } from 'components/NarrativeField';
import { FeaturesConsumer } from 'common/FeaturesProvider';
import { Features } from '../../api/types/types';
import { InsertDriveFile } from '@material-ui/icons';
import { countryTimeZone } from '../../App';

interface Props {
    timer: ImmutableTimer;
    segmentChange?: (newSeg: TimerChunk) => void;
    onAddNote?: () => void;
}
interface RunningTimerChunkState {
    // tslint:disable-next-line:no-any
    interval?: any;
    durTxt: string;
}
export default class RunningTimerChunkItem extends React.Component<Props, RunningTimerChunkState> {

    constructor(props: Props) {
        super(props);
        this.state = {
            durTxt: this.getTimerDurationTxt(),
            interval: props.timer.active ? setInterval(
                this.timerTick,
                250) : undefined,
        }
    }

    narrativeChange = () => {
        return ;
        // const newChunk = JSON.parse(JSON.stringify(this.props.timer));
        // newChunk.description = text;
        // if (this.props.segmentChange) {
        //     this.props.segmentChange(newChunk);
        // }
    }
    // tslint:disable-next-line:no-any
    onClickNarrative = () => {
        if (this.props.onAddNote) {
            this.props.onAddNote();
        }
    }
    timerTick = () => {
        this.setState({durTxt: this.getTimerDurationTxt()})
    }
    getTimerDurationTxt = () => { 
        const timer = this.props.timer;
        let secs = 0;
        if (timer.active) {
            let da = Math.floor((new Date().getTime() - new Date(timer.startedOn!).getTime()) / 1000);
            secs += da;
        }
        // secs = Math.floor(secs / 1000);
        let hours   = Math.floor(secs / 3600);
        let minutes = Math.floor((secs - (hours * 3600)) / 60);
        let seconds = secs - (hours * 3600) - (minutes * 60);
        let hstring = `${hours}`;
        let sstring = `${seconds}`;
        let mstring = `${minutes}`;
        if (hours   < 10) {hstring   = '0' + hstring; }
        if (minutes < 10) {mstring = '0' + mstring; }
        if (seconds < 10) {sstring = '0' + sstring; }
        return `${hstring}:${mstring}:${sstring}`
    }
    onChangeDummy = () => {
        // do nothing on change
    }
    render() {
        let {timer, onAddNote} = this.props;
        const countrycode = countryTimeZone.getTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
        const locale = (countrycode.country || 'US').toUpperCase() === 'US' ? 'en-US' : 'en-GB';
        return (
            <FeaturesConsumer>
                {(features: Features) =>
                <div style={{ height: 80 }}>
                    <Styled.SegmentPaper submitted={false} hasEntry={false} square={true}>
                        <Styled.Control>
                        <Checkbox
                            onClick={(e) => e.stopPropagation()}
                            disableRipple={true}
                            style={{padding: 0}}
                            disabled={true}
                        />
                        </Styled.Control>
                        <Styled.DateContainer>
                            {(new Date(timer.startedOn!)).toLocaleDateString(locale,
                                {year: 'numeric', month: '2-digit', day: '2-digit'})}
                        </Styled.DateContainer>
                        <Styled.TimeContainer>
                            {this.getTimerDurationTxt()}
                        </Styled.TimeContainer>
                        <Styled.NarrativeContainer>
                            <div onClick={this.onClickNarrative} style={{width: '100%'}}>
                                <NarrativeField
                                    onChange={this.onChangeDummy}
                                    onBlur={this.narrativeChange}
                                    value={timer.notes!}
                                    placeholder="Narrative"
                                    maxLength={features.EpochConfigNarrativesMaximumChars}
                                    disabled={true}
                                    style={{maxHeight: 52}}
                                />
                            </div>
                        </Styled.NarrativeContainer>
                        <Styled.RunningItemActionContainer>
                            <Styled.CompactIconButton onClick={onAddNote}>
                                <InsertDriveFile fontSize="small" />
                            </Styled.CompactIconButton>
                        </Styled.RunningItemActionContainer>
                    </Styled.SegmentPaper>
                </div>
                }
            </FeaturesConsumer>
        )
    }
}