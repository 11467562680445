import { observable, action, computed } from 'mobx';
import BaseStore from 'store/base.store';
import { removeListItem, setListItem } from 'util/array';
import { RootStore } from '../root.store';
import ImmutableTimer from 'api/immutables/ImmutableTimer';
import Mutex, { ApiResult } from 'api/util';
import { Client, Features, Matter, TimerChunk } from '../../api/types/types';
import ImmutableTemplate from '../../api/immutables/ImmutableTemplate';
import ImmutableTimeEntry, { SapStatus } from '../../api/immutables/ImmutableTimeEntry';
import { DateTime } from 'luxon';
import { debounce } from 'typescript-debounce-decorator';
import { TimerFormValidation } from './timer.new.dialog.store';
import { Platform } from '../../util/Platform';
import { ValidateSave } from 'api/immutables/validators';
import { tabex } from '../../api/implementations/web/Session.impl';
import { PopoutErrorMessage } from 'components/TimerPopOut/TimerPopoutErrorDialog';
import { sortByAlphaNumeric } from '../../util/utils'
import { TimerChunkI } from '../../api/implementations/electron/Dexie';
import * as React from 'react';
import logger from '../../logging/logging';

export const TIMER_POPOUT_WINDOW_SETTINGS = `
        fullscreen=0,
        minWidth=250,
        maxWidth=300,
        width=300,
        minHeight= 120,
        height=600,
        status=0,
        titlebar=0,
        toolbar=0,
        location=0,
        menubar=0,
        resizable=0,
        alwaysOnTop=1,
        maximizable=0`
;

interface AssignSelectedMsg {
    message: string,
    errorColor: boolean
}

export default class TimerStore extends BaseStore {
    @observable timers: ImmutableTimer[] = [];
    @observable selectedTimer: ImmutableTimer;
    @observable searchText: string = '';
    @observable chunks: TimerChunk[] = [];
    @observable matter?: Matter | null;
    @observable template?: ImmutableTemplate | null;
    @observable timerLoading: boolean = false;
    @observable selectedChunks: number[] = [];
    @observable timerNote?: string = '';
    @observable validation: TimerFormValidation = { name: false};
    @observable selectedTimerIds: Set<number | undefined> = new Set();
    @observable toggleSelectAll: boolean = false;
    @observable searchTextInPopOut: string = '';
    @observable popOutOnTop: boolean = true;
    @observable popOutOpen: boolean = false;
    @observable shouldUpdateChunkProps: boolean = false;
    @observable popOutLoader: boolean = false;
    @observable mainWinLoading: boolean = false;
    @observable clientFilter: Client | null = null;
    @observable matterFilter: Matter | null = null;
    @observable sortByAlphaNumericToggle: boolean | undefined = undefined;
    @observable filterTodayTimersFlag: boolean = false;
    @observable applyingFilter: boolean = false;
    @observable loadTimersBool: boolean = false;
    @observable playAndStopLoading: string = '';
    @observable activeTimerUpdateSteps: string = ''; // enable/disable timers lists when popup open
    @observable clientFilteredTimers: ImmutableTimer[] = [];
    @observable matterFilteredTimers: ImmutableTimer[] = [];
    // @observable validation: TemplateFormValidation = { name: false, matter: false};

    originalTimer: ImmutableTimer;
    originalTemplate: ImmutableTemplate | null;
    originalMatter: Matter | null;
    mutex: Mutex = new Mutex();
    @observable loadingTimer?: number;
    handlerDestructor: () => void;
    // tslint:disable-next-line:no-any
    externalWindow: any;
    tabexClient =  tabex.client();
    loadingTimeout: number;
    popOutSetTimeout: number; // used for timeOut when popOut open

    constructor(rootStore: RootStore) {
        super(rootStore);
        this.initializeHandler();
        this.selectChunksHandler();
        if (Platform.isWeb()) {
            window.onunload = () => {
                this.closePopOut();
            }
        }
        if (Platform.isElectron()) {
            const { ipcRenderer } = require('electron');
            ipcRenderer.on('timer-pop-out-close', () => {
                this.popOutOpen = false;
            })
            ipcRenderer.on('new-timer-dialogue-open', () => {
                this.newTimer();
            })
        }
        this.tabexClient.on('setUpdateChunkProps', this.setUpdateChunkProps);
        this.tabexClient.on('assignSelected', this.assignSelected);
        this.tabexClient.on('showMessage', this.showAssignSelectedMessages)
        this.tabexClient.on('mainTimerLoader', this.mainTimerLoader);
        this.tabexClient.on('timerListLoader', this.timerListLoader);
        this.tabexClient.on('selectedTimerLoader', this.selectedTimerLoader);
        this.tabexClient.on('receiveTimers', this.recieveTimersOnAllTabs);
        this.tabexClient.on('receiveChunks', this.receiveChunks);
    }
    initializeHandler = () => {
        this.handlerDestructor = this.rootStore.api.Timer.registerReciever(this.recieveTimers)
    }
    selectChunksHandler = () => {
        if (this.rootStore.api.Timer.updatedChunksListener) {
            this.rootStore.api.Timer.updatedChunksListener(this.updateServerChunks)
        }
    }
    @action.bound updateServerChunks = (updatedChunks: TimerChunk[]) => {
        this.tabexClient.emit('receiveChunks', updatedChunks);
        const negativeChunkIndex = this.chunks.findIndex(sc => sc.id! < 0);
        const negativeChunk = this.chunks[negativeChunkIndex] as TimerChunkI;
        const negativeSelectedChunkIndex = this.selectedChunks.findIndex(sc => sc < 0);
        if (negativeChunkIndex > -1) {
            updatedChunks.map((chnk) => {
                const positiveChunk = chnk as TimerChunkI;
                if (positiveChunk.localId) {
                    if (positiveChunk.localId === negativeChunk.localId) {
                        this.chunks.splice(
                            negativeChunkIndex,
                            1,
                            positiveChunk
                        )
                        if (negativeSelectedChunkIndex > -1) {
                            this.selectedChunks.splice(
                                negativeSelectedChunkIndex,
                                1,
                                positiveChunk.id!
                            )
                        }
                    }
                }
            })
        }
    }
    @action.bound async newTimer() {
        if (await this.rootStore.canIChangeScope()) {
            try {
                if (this.rootStore.routerStore.location.pathname !== `/timers` && !this.selectedTimer) {
                    this.rootStore.routerStore.push(`/timers`)
                }
                let timers = await this.rootStore.newTimerDialogStore.open() as ImmutableTimer[];
                // if (!timers) { return };
                this.recieveTimers(timers);
            } catch (e) {
                logger.info('Timers, New Timer dialog has been Cancelled.\n', e);
                return;
            }
        }
        this.validation = Object.assign({ name: false });
    }
    recieveTimersOnAllTabs = (timers: ImmutableTimer[]) => {
        // this.mutex.execute(async () => {
        let isSelectedTimerIncluded = false;
        timers.forEach(t => {
            if (t.timeKeeperId !== this.rootStore.api.Session.currentTimeKeeper) {
                return;
            }
            if (this.matterFilter) {
                this.checkLocalTimers(t, this.matterFilteredTimers);
            } else if (this.clientFilter) {
                this.checkLocalTimers(t, this.clientFilteredTimers);
            }
            this.checkLocalTimers(t, this.timers);
            if (t.deleted) {
                if (this.matterFilter) {
                    this.matterFilteredTimers = removeListItem(this.matterFilteredTimers, t.id!)
                } else if (this.clientFilter) {
                    this.clientFilteredTimers = removeListItem(this.clientFilteredTimers, t.id!)
                }
                this.timers = removeListItem(this.timers, t.id!);
                if (this.selectedTimer && this.selectedTimer.id === t.id!) {
                    this.selectedTimer = new ImmutableTimer();
                    this.originalTimer = this.selectedTimer.clone();
                    if (this.rootStore.routerStore.location.pathname === `/timers/${t.id}`) {
                        this.rootStore.routerStore.push(`/timers`);
                    }
                }
                return;
            }
            if (this.clientFilter || this.matterFilter) {
                this.resetSelectedTimer(this.filteredTimers);
                if (this.matterFilter) {
                    if (this.matterFilteredTimers.find(x => x.id === t.id)) {
                        // Updating the timers when in a filter within the same list
                        // As we dont need to update the others
                        let newLocal1 = this.matterFilteredTimers.slice();
                        setListItem(newLocal1, t);
                        this.matterFilteredTimers = newLocal1
                    } else {
                        // This is called when new timer is added to the list as it isnt 
                        // available in the filtererd list of timers
                        this.setMatterFilter(this.matterFilter, false, true)
                    }
                } else if (this.clientFilter) {
                    if (this.clientFilteredTimers.find(x => x.id === t.id)) {
                        let newLocal2 = this.clientFilteredTimers.slice();
                        setListItem(newLocal2, t);
                        this.clientFilteredTimers = newLocal2
                    } else {
                        this.setClientFilter(this.clientFilter, false, true)
                    }
                }
            }
            let newLocal = this.timers.slice();
            setListItem(newLocal, t);
            this.timers = newLocal;
            if (this.selectedTimer && t.id === this.selectedTimer.id) {
                isSelectedTimerIncluded = true;
                if (Platform.isElectron()) {
                    if (!!this.playAndStopLoading) {
                        this.playAndStopLoading = this.playAndStopLoading === 'update' ? 'write' : 'done';
                    }
                    this.loadTimer(t.id!);
                } else {
                    this.wrappedLoadTimer(t.id!);
                }
            }
        });
        // to sync timers segements in Day View (Home Page) if running Desktop and Web at the same time
        this.rootStore.homeStore.setTimersForDay();
        // (timers.length === 0) case for Destop in offline mode, (!isSelectedTimerDirty) if played/stopped timer not selected
        if (!!this.playAndStopLoading && (timers.length === 0 || !isSelectedTimerIncluded)) {
            this.playAndStopLoading = this.playAndStopLoading === 'update' ? 'write' : '';
        }
        if (!!this.activeTimerUpdateSteps && this.activeTimerUpdateSteps !== 'N/A') {
            if (this.activeTimerUpdateSteps === 'two') {
                this.activeTimerUpdateSteps = 'one';
            } else {
                clearTimeout(this.popOutSetTimeout);
                // enable timers lists
                this.activeTimerUpdateSteps = '';
                this.tabexClient.emit('timerListLoader', '');
            }
        }
        this.validation = Object.assign({ name: false });
        // });
    }
    @action recieveTimers = (timers: ImmutableTimer[]) => {
        const immutable = timers.map(t => Object.assign(new ImmutableTimer(), t));
        this.tabexClient.emit('receiveTimers', immutable, true);
    }
    checkLocalTimers = (timer: ImmutableTimer, timers: ImmutableTimer[]) => {
        if (Platform.isElectron()) {
            const dexieTimer = timer as unknown as TimerChunkI;
            const localNegativeIdx = timers.findIndex(t => t.id! < 0);
            const localNegativeTimer = timers[localNegativeIdx] as unknown as TimerChunkI;
            if (localNegativeIdx > -1) {
                if (dexieTimer.localId === localNegativeTimer.localId) {
                    if ((this.selectedTimer as unknown as TimerChunkI).localId === dexieTimer.localId) {
                        this.selectedTimer = Object.assign(new ImmutableTimer(), { ...this.selectedTimer, id: dexieTimer.id })
                    }
                    timers
                        .splice(localNegativeIdx, 1, Object.assign(new ImmutableTimer(), dexieTimer))
                        .slice();
                }
            }
        }
    }
    receiveChunks = (chunks: TimerChunk[]) => {
        chunks.forEach((chunk) => {
            if ((chunk.deleted || chunk.submitted) && chunk.id) {
                this.chunks = removeListItem(this.chunks, chunk.id)
                return;
            }
            if (!this.rootStore.appStore.features.EpochConfigTimeSegmentsSubmittedVisible && chunk.submitted && chunk.id) {
                this.chunks = removeListItem(this.chunks, chunk.id)
                return;
            }
            let newChunkLocal = this.chunks.slice();
            setListItem(newChunkLocal, chunk);
            this.chunks = newChunkLocal.slice();
        })
    }
    @computed get sortedChunks() {
        return this.chunks.slice()
            .filter(c => c.timerId === this.selectedTimer.id)
            .sort((a, b) => {
            let dateA = new Date(b.startTime);
            let dateB = new Date(a.startTime);
            return  dateA > dateB ? -1 : dateB > dateA ? 1 : 0;
        }).reverse();
    }
    @computed get dirty(): boolean {
        return !!(this.selectedTimer && this.selectedTimer.dirty)
    }
    @action resetDirty = () => {
        this.selectedTimer = this.originalTimer.clone();
        this.template = this.originalTemplate;
        this.matter = this.originalMatter;
    }
    @action loadTimers = async () => {
        this.loadTimersBool = true;
        if (!this.applyingFilter) {
            this.selectedTimer = new ImmutableTimer();
        }
        this.timers = await this.rootStore.api.Timer.getAll();
        this.fetchLastmodifiedTimer();
        this.loadTimersBool = false;
    }
    @action.bound async wrappedLoadTimer(id: number) {
        this.timerLoading = true;
        await this.loadTimer(id);
        this.timerLoading = false;
    }
    @action loadTimer = async (id: number) => {
        const previousSelelectedTimer = this.selectedTimer;
        try {
            let showSubmitted = this.rootStore.appStore.features.EpochConfigTimeSegmentsSubmittedVisible;
            this.selectedTimer = await this.rootStore.api.Timer.get(id);
            this.originalTimer = this.selectedTimer.clone();
            // this.chunks = (await this.rootStore.api.Timer.getChunks(id))
            this.chunks = this.selectedTimer.chunks
                .filter(tc => {
                    if (tc.deleted) {
                        return false;
                    }
                    if (showSubmitted) {
                        return true;
                    }
                    return !tc.submitted;
                });
            if (this.selectedTimer.matterId) {
                this.matter = await this.rootStore.api.Matter.get(this.selectedTimer.matterId);
            } else {
                this.matter = null;
            }
            if (this.selectedTimer.templateId) {
                this.template = await this.rootStore.api.Template.getTemplate(this.selectedTimer.templateId)
                if (this.template.deleted === true) {
                    this.template = null;
                }
            } else {
                this.template = null;
            }
            this.originalMatter = this.matter;
            this.originalTemplate = this.template;
        } catch (e) {
            logger.error('Timers, Load Timer Error.\n', e);
        } finally {
            if (this.playAndStopLoading === 'done') {
                this.playAndStopLoading = '';
            }
            if (previousSelelectedTimer.id !== this.selectedTimer.id) {
                this.tabexClient.emit('selectedTimerLoader', this.selectedTimer);
            }
        }
    }
    @action.bound setTemplate(template?: ImmutableTemplate) {
        this.template = template;
        let newt = this.selectedTimer.clone()

        if ( !newt.name && template) {
            newt.name = template.name
            this.validation = Object.assign({ name: false });
        }

        if (!template) {
            newt.templateId = undefined;
        } else {
            newt.templateId = template.id!;
        }
        this.matter = null;
        newt.matterId = undefined;
        newt.dirty = true;
        this.selectedTimer = newt;
    }
    @action.bound async openTimeEntry(timeEntryId: number, timerId: number) {
        try {
            let timeEntryInTimer = await this.rootStore.api.TimeEntry.getEntry(timeEntryId);
            if (timeEntryInTimer.matterId) { // if Matter exists, then hydrate entry with code set flags
                const codeSetFlags = await this.rootStore.api.Code.determineCodeSetFields(
                    timeEntryInTimer.matterId, timeEntryInTimer.workDateTime
                );
                timeEntryInTimer.isActCode = codeSetFlags.isActCode;
                timeEntryInTimer.isPhaseCode = codeSetFlags.isPhaseCode;
                timeEntryInTimer.isFfTaskCode = codeSetFlags.isFfTaskCode;
            }
            
            this.rootStore.timeEntryDialogStore.open(timeEntryInTimer, undefined, true, (results) => {
                this.rootStore.api.Timer.updateTimerDurationFromTimeEntry([results[0].object.id!])
            });
        } catch (e) {
            logger.error('Time Entries, Opening Time Entry failed.\n', e)
        }
        // let timer =  await this.rootStore.api.Timer.get(timerId);
        // if (timer) {
        //     this.rootStore.homeStore.setTimersForDay();
        // }
        // this.recieveTimers([timer]);
    }
    @action.bound async setMatter(matter?: Matter) {
        if (matter) {
            if (!matter.tracked) {
                await this.rootStore.api.Matter.track([matter.id]);
            }
            this.matter = await this.rootStore.api.Matter.get(matter.id);
        } else {
            this.matter = matter;
        }
        let newt = this.selectedTimer.clone()
        if ( !newt.name && matter) {
            newt.name = matter.name
            this.validation = Object.assign({ name: false });
        }

        if (this.matter) {
            newt.matterId = this.matter.id;
        } else {
            newt.matterId = undefined;
        }
        this.template = null;
        newt.templateId = undefined;
        newt.dirty = true;
        this.selectedTimer = newt;
    }
    // disable/enable list in the other window
    timerListLoader = (updateSteps: string) => {
        this.activeTimerUpdateSteps = updateSteps;
        clearTimeout(this.popOutSetTimeout);
        if (!!updateSteps) {
            // setTimeout for case when playing timer then close before finish loading
            this.popOutSetTimeout = window.setTimeout(() => { this.activeTimerUpdateSteps = '' }, 10000);
            if (updateSteps !== 'N/A') {
                this.tabexClient.emit('timerListLoader', 'N/A');
            }
        }
    }
    // set selectedTimer in popOut when it is open to enable/disable list
    selectedTimerLoader = (selectedTimer: ImmutableTimer) => {
        // when called from select timer (main timer window)
        if (selectedTimer) {
            this.selectedTimer = selectedTimer;
        } else {
            // when opening popOut, selectedTimer is empty Immutable object, will result in Recursion call and selectedTimer will be set
            this.tabexClient.emit('selectedTimerLoader', this.selectedTimer);
        }
    }
    @action stopTimer = (timerId: number, updatedTimersInHome?: boolean) => async () => {
        if (await this.rootStore.canIChangeScope()) {
            this.tabexClient.emit('timerListLoader', Platform.isElectron() ? 'two' : 'one');
            this.selectedChunks = [];
            // this.mutex.execute(async () => {
            this.loadingTimer = timerId;
            this.rootStore.api.Timer.stop().then((toStop) => {
                if (toStop) {
                    this.recieveTimers([toStop]);
                    if (Platform.isElectron()) {
                        this.playAndStopLoading = 'update';
                        // clearTimeout to confirm there is net one from previous attempt  
                        clearTimeout(this.loadingTimeout);
                        // timeout to enable timers after 10 seconds if an error prevents reseting playAndStopLoading
                        this.loadingTimeout = window.setTimeout(() => {
                            if (this.playAndStopLoading !== '') {
                                this.playAndStopLoading = '';
                            }
                        }, 10000);
                    }
                    if (updatedTimersInHome) {
                        this.rootStore.homeStore.tabexClient.emit('updatedTimersInHomePage');
                    }
                    this.loadingTimer = undefined;
                }
            });
            
            // })
        }
    }
    @action startTimer = (timer: ImmutableTimer) => async () => {
        if (await this.rootStore.canIChangeScope()) {
            const isPlaying = this.timers.filter(t => t.active).length > 0;
            this.tabexClient.emit('timerListLoader', Platform.isElectron() && isPlaying ? 'two' : 'one');
            // this.setUpdateChunkProps(true);
            this.selectedChunks = [];
            // this.mutex.execute(async () => {
            this.loadingTimer = timer.id;
            this.rootStore.api.Timer.start(Object.assign(new ImmutableTimer(), timer), isPlaying).then((started) => {
                this.recieveTimers(started);
                if (Platform.isElectron()) {
                    this.playAndStopLoading = timer.id === this.selectedTimer.id ? 'write' : 'update';
                    // clearTimeout to confirm there is net one from previous attempt
                    clearTimeout(this.loadingTimeout);
                    // timeout to enable timers after 10 seconds if an error prevents reseting playAndStopLoading
                    this.loadingTimeout = window.setTimeout(() => {
                        if (this.playAndStopLoading !== '') {
                            this.playAndStopLoading = '';
                        }
                    }, 10000);
                }
                this.loadingTimer = undefined;
            });
            
            // })
            this.validation = Object.assign({ name: false });
        }
    }
    setMatterFilterValue = () => {
        this.matterFilter = null;
    }
    setClientFilterValue = () => {
        this.clientFilter = null;
    }
    resetTodayTimerFlag = () => {
        this.filterTodayTimersFlag = false;
    } 
    @action deleteTimer = (timer: ImmutableTimer) => async () => {
        if (await this.rootStore.canIChangeScope()) {
            try {
                const confirmDelete = await this.rootStore.deleteConfirmationDialog.open();
                if (!confirmDelete) { return };
                let newt = timer.clone();
                newt.deleted = true;
                await this.rootStore.api.Timer.updateTimerSync(newt);
            } catch (e) {
                logger.info('Timers, Timer Deletion has been Cancelled.\n', e);
                return;
            }
            this.rootStore.snackbarStore.triggerSnackbar('Deleted Successfully');
            // this.recieveTimers([newt])
        }
    }
    @action changeSelectedTimer = async (id: number | null | undefined) => {
        if (!this.selectedTimer || id !== this.selectedTimer.id) {
            if (await this.rootStore.canIChangeScope()) {
                const notDirty = await this.rootStore.routerStore.attemptPush(`/timers/${id}`);
                let timer = this.timers.find(t => t.id === id);
                if (timer && notDirty) {
                    timer = Object.assign(new ImmutableTimer(), timer);
                    this.selectedTimer = timer.clone();
                    this.originalTimer = timer.clone();
                    this.selectedChunks = [];
                }
            }
            this.validation = Object.assign({ name: false });
        }
    }
    
    @action onSearchChange = (input: string) => {
        this.searchText = input;
    }
    @action.bound async revert() {
        await this.wrappedLoadTimer(this.selectedTimer.id!);
        this.validation = Object.assign({ name: false });
    }
    
    deleteChunk =  (chunk: TimerChunk) => async () => {
        try {
            const confirmDelete = await this.rootStore.deleteConfirmationDialog.open();
            // if (!confirmDelete) { return };

            this.rootStore.appStore.setChunkSaveLoader(true);
            let writeChunk = {
                    ...chunk,
                    deleted: true
            };
            if (Platform.isElectron()) {
                this.playAndStopLoading = 'update';
            }
            let result = await this.rootStore.api.Timer.updateChunks([writeChunk]);
            if (!result[0].status.failed) {
                this.rootStore.snackbarStore.triggerSnackbar('Deleted Successfully');
            }
            let timer =  await this.rootStore.api.Timer.get(this.selectedTimer.id!);
            let deletedChunkIdx = this.selectedChunks.findIndex(c => c === chunk.id);
            this.selectedChunks.splice(deletedChunkIdx, 1);
            this.recieveTimers([timer]);
            this.rootStore.appStore.setChunkSaveLoader(false);
            // TODO: immediate delete with confirmation
        } catch (e) {
            logger.info('Timers, Chunk Deletion has been Cancelled.\n', e);
            this.playAndStopLoading = '';
            this.rootStore.appStore.setChunkSaveLoader(false);
            return;
        }
    }
    @action.bound async debouncedChunkSave(chunk: TimerChunk) {
        this.setUpdateChunkProps(false);
        this.rootStore.appStore.setChunkSaveLoader(true);
        let result = await this.rootStore.api.Timer.updateChunks([chunk]);
        // let timer = await this.rootStore.api.Timer.get(chunk.timerId);
        let updated = result[0].object as TimerChunk;
        // this.recieveTimers([this.selectedTimer]);
        this.receiveChunks([updated]);
        this.rootStore.appStore.setChunkSaveLoader(false);
    }
    @action.bound @debounce(1000, {leading: false}) async debouncedTimerSave() {
        let result = await this.rootStore.api.Timer.updateTimers([ this.selectedTimer ]);
    }
    runningChunkEdit = async( newChunk: TimerChunk) => {
        this.selectedTimer.notes = newChunk.description;
        this.debouncedTimerSave();
    }
    editChunk = (chunk: TimerChunk) => () => {
        // TODO: open chunk edit dialog
    }
    chunkEdit = (index: number) => async (newChunk: TimerChunk) => {
        this.clearSelections();
        // index in prop is for sortedChunks
        let indexOfEditedChunk = this.chunks.findIndex(chunk => chunk.id === newChunk.id)
        this.chunks[indexOfEditedChunk] = newChunk;
        this.chunks = this.chunks.slice();
        await this.debouncedChunkSave(newChunk);
        this.rootStore.snackbarStore.triggerSnackbar('Saved successfully')
        // TODO: edit the chunk, immediately save to back end.
        // Make sure the TimerSegment component only emits debounced changes
    }
    setUpdateChunkProps = (val: boolean) => {
        this.shouldUpdateChunkProps = val;
    }
    mainTimerLoader = (val: boolean) => {
        this.mainWinLoading = val;
    }
    @computed get showSelectAll() {
       if (this.chunks.length === 0) { return false };
       return this.chunks.map((cid) => {
        if (cid.timeEntryId! === null || cid.timeEntryId! === undefined) {
            return true;
        } else {
            return false;
        }});
    }
    @computed get selectAllStatus() {
        let disabledChunksCount: number = 0;
        this.chunks.forEach((chunk) => {
            if (chunk.timeEntryId) {
                disabledChunksCount++;
            }
        })
        return ( this.selectedChunks.length === this.chunks.length - disabledChunksCount ) ?
           this.chunks.length !== disabledChunksCount ? true : false : false;
    }
    @action.bound async toggleSelect(evt: React.ChangeEvent<HTMLInputElement>, id: number) {
        if (this.rootStore.appStore.online && id < 0) {
            evt.preventDefault();
            return ;
        }
        if (await this.rootStore.canIChangeScope()) {
            if (this.selectedChunks.includes(id)) {
                this.selectedChunks = this.selectedChunks.filter(i => i !== id);
            } else {
                this.selectedChunks = this.selectedChunks.concat([id])
            }
        }
    }
    @action.bound setName(name: string) {
        let newt = this.selectedTimer.clone();
        newt.name = name;
        newt.dirty = true;
        this.selectedTimer = newt;
        this.validation = Object.assign({ name: false });
    }
    @action.bound clearSelections() {
        this.selectedChunks = [];
    }
    @action.bound async deleteSelectedChunks() {
        if (this.selectedTimer.dirty) {
            const unsavedConfirmation = await this.rootStore.unsavedStore.open();
            if (!unsavedConfirmation) { return; }
        }
        try {
            const confirmDelete = await this.rootStore.deleteConfirmationDialog.open();
            this.rootStore.appStore.setChunkSaveLoader(true);
            if (!confirmDelete) { return };
            let writeChunks = this.selectedChunkObjs.map(it => (
                {
                    ...it,
                    deleted: true
                }
            ))
            if (Platform.isElectron()) {
                this.playAndStopLoading = 'update';
            }
            let result = await this.rootStore.api.Timer.updateChunks(writeChunks);

            let timer =  await this.rootStore.api.Timer.get(this.selectedTimer.id!);
            this.rootStore.snackbarStore.triggerSnackbar('Deleted Successfully');
            this.recieveTimers([timer]);
            this.selectedChunks = [];
            this.rootStore.appStore.setChunkSaveLoader(false);
            // TODO:
        } catch (e) {
            logger.info('Timers, Delete Selected Chunks has been Cancelled.\n', e);
            this.playAndStopLoading = '';
            this.rootStore.appStore.setChunkSaveLoader(false);
            return;
        }
    }
    @action.bound async selectAllTimeSegments(evt: React.ChangeEvent<HTMLInputElement>) {
        if (this.rootStore.appStore.online && this.selectedTimer.chunks.filter(c => c.id! < 0).length > 0) {
            evt.preventDefault();
            return ;
        }
        let checkedEvent = evt.target.checked
        if (await this.rootStore.canIChangeScope()) {
            if (checkedEvent) {
                this.selectedChunks = [];
                this.chunks.map((cid) => {
                    if (cid.timeEntryId === null || cid.timeEntryId! === undefined) {
                        this.selectedChunks = this.selectedChunks.concat([cid.id!])
                    }
                });
            } else {
                this.selectedChunks = [];
            }
        }
    }
    validateTimerName = () => {
        let duplicates = this.timers.filter((t) => {
            return (t.id !== this.selectedTimer.id) ? 
                    (t.name || '').trim().toUpperCase() ===
                        (this.selectedTimer.name || '').trim().replace(/\s\s+/g, ' ').toUpperCase()
                    : false;
        })
        if (duplicates.length > 0) {
            this.validation = {
                ...this.validation,
                name: 'Name already exists'
            }
        }
        if (this.selectedTimer.name.length > 100) {
            this.validation = {
                ...this.validation,
                name: 'Name cannot exceed 100 characters'
            }
        }
        if (this.selectedTimer.name.trim() === '') {
            this.validation = Object.assign({ name: 'Required Field'});
        }
        return !this.validation.name;
    }
    @action.bound async saveTimer() {
        if (!this.validateTimerName()) {
            return;
        }
        // cloning and trimming once again done here so that we can save trimmed value to backend
        let clonedTimer = this.selectedTimer.clone();
        clonedTimer.name = clonedTimer.name.trim().replace(/\s\s+/g, ' ')
        this.selectedTimer = clonedTimer;
        let results = await this.rootStore.api.Timer.updateTimers([ this.selectedTimer ]);
        if (results) {
            if (results[0].status.failed) {
                alert('Server error. Please try again');
            } else {
                let newt = Object.assign(new ImmutableTimer(), results[0].object);
                newt.convertedDuration = this.selectedTimer.convertedDuration
                newt.pendingDuration = this.selectedTimer.pendingDuration
                newt.totalDuration = this.selectedTimer.totalDuration
                newt.dirty = false;
                this.rootStore.snackbarStore.triggerSnackbar('Saved Successfully');
                this.recieveTimers([newt]);
            }
        }
    }
    @action.bound addTimerNote = (timer: ImmutableTimer) => async () => {
        let note = await this.rootStore.narrativeDialogStore.open();
        // this.recieveTimers([newt]);
    };
    @action.bound saveTimerNote = async (note: string) => {
        if (!note) { note = '' }
        let newt = this.activeTimer.setNote(note as string);
        try {
            await this.rootStore.api.Timer.updateTimerSync(newt);
            this.rootStore.snackbarStore.triggerSnackbar('Added Narrative Successfully');
        } catch (e) {
            this.recieveTimers([newt]);
        }
    }
    @computed get activeTimer(): ImmutableTimer {
        const currentTimer = this.timers.filter(timer => !!timer.active)[0] || this.timers[0];
        return Object.assign(new ImmutableTimer(), currentTimer);
    }
    @action.bound async entryFromSelectedChunks() {
        if (await this.rootStore.canIChangeScope()) {
            const chks = this.selectedChunkObjs.sort((a, b) => {
                return (a.startTime > b.startTime) ? 1 : -1
            });
            const temp = this.template ? this.template.id : null;
            let startDate = new Date(Math.min.apply(null, chks.map((ch) => {
                return new Date(ch.startTime);
            })));

            let newEntry = await this.buildEntryFromChunks(startDate.toString(), chks, this.matter, temp);
            this.rootStore.timeEntryDialogStore.createAnotherFlag = false
            let entry = await this.rootStore.timeEntryDialogStore.open(
                newEntry, undefined, true, 
                (results) => {
                    if (results.length > 1) {
                        throw 'Fatal: there was more than 1 time entry created.'
                    } else if (results.length === 0) {
                        throw 'Fatal: no time entry was created.'
                    }
                    if (!results[0].status.failed) {
                        let writeChunks = chks.map((chk) => {
                            return {
                                ...chk,
                                timeEntryId: results[0].object.id!
                            }
                        })
                        this.selectedChunks = [];
                        this.rootStore.api.Timer.updateChunks(writeChunks).then(res => {
                            // TODO: Implement this code in Impl.
                            if (Platform.isWeb()) {
                                const chunks = res.map(c => c.object);
                                this.tabexClient.emit('receiveChunks', chunks, true);
                                const distinctTimerIds = Array.from(new Set(chunks.map(c => c.timerId)))
                                this.rootStore.api.Timer.getTimersFromIds(distinctTimerIds).then(timersToUpdate => {
                                    this.recieveTimers(timersToUpdate);
                                });
                            }
                        })
                    }
                }) as ImmutableTimeEntry;
        }
    }
    
    async buildEntryFromChunks(startTime: string, chunks: TimerChunk[], 
                               matter?: Matter | null, templateId?: number | null): Promise<ImmutableTimeEntry> {
        const chks = chunks;
        let d = new Date(startTime);
        let date = DateTime.local(d.getFullYear(), d.getMonth() + 1, d.getDate(), 0, 0, 0, 0);
        let newEntry = new ImmutableTimeEntry();
        let nars: string[] = [];
        let tempNarrative: string = '';
        let secs = chks.reduce((prev, cur) => {
            let tot =  Math.ceil(((new Date(cur.endTime)).getTime() - (new Date(cur.startTime)).getTime()) / 1000);
            if (cur.description && cur.description.trim().length > 0) {
                nars.push(cur.description.trim())
            }
            return prev + tot;
        }, 0);
        newEntry.workDateTime = date.toISO();
        newEntry.timeKeeperId = this.rootStore.api.Session.currentTimeKeeper!;
        const actTk = this.rootStore.appStore.getActiveTimeKeeperForDate(date);
        if (matter) {
            newEntry = newEntry.setMatter(matter);
            if (newEntry.matter) {
                const codeSetFlags = await this.rootStore.api.Code.determineCodeSetFields(
                    newEntry.matter.id, newEntry.workDateTime
                )
                newEntry.isActCode = codeSetFlags.isActCode;
                newEntry.isPhaseCode = codeSetFlags.isPhaseCode;
                newEntry.isFfTaskCode = codeSetFlags.isFfTaskCode;
            }
        }
        if (templateId) {
            const template = await this.rootStore.api.Template.getTemplate(templateId)
            this.rootStore.timeEntryDialogStore.selectedTemplate = template;
            if (template) {
                const codeSetFlags = await this.rootStore.api.Code.determineCodeSetFields(
                    template.matter!.id, newEntry.workDateTime
                )
                template.isActCode = codeSetFlags.isActCode;
                template.isPhaseCode = codeSetFlags.isPhaseCode;
                template.isFfTaskCode = codeSetFlags.isFfTaskCode;
            }
            newEntry = newEntry.loadFromTemplate(template)
            tempNarrative = newEntry.narrative ? newEntry.narrative : '';
        }
        newEntry =
            newEntry.
            setDuration(secs).
            setOffice(actTk ? actTk.office : undefined ).
            setOfficeName(actTk ? actTk.officeName : undefined);
        newEntry.narrative = (tempNarrative + ' ' + nars.join(' ')).trim();
        newEntry.sapStatus = SapStatus.UNSUBMITTED;
        newEntry.timeKeeperId = this.rootStore.api.Session.currentTimeKeeper!;
        return newEntry;
    }
    searchWithinObject(timer: ImmutableTimer, query: string): boolean {
        let tmpName = timer.name ? timer.name : '', 
            
            searchText = query.toLowerCase();

        return tmpName.toLowerCase().includes(searchText);
    }
    @computed get selectedChunkObjs(): TimerChunk[] {
        return this.selectedChunks
            .map(cid => this.chunks.find((c) => c.id === cid)!)
            .filter(c => c);
    }
    fetchLastmodifiedTimer = () => {
        this.sortByAlphaNumericToggle = undefined;
    }
    toggleAlphaNumericHandler = () => {
        this.sortByAlphaNumericToggle = !this.sortByAlphaNumericToggle;
    }
    @action.bound isActiveToday(timer: ImmutableTimer): boolean {
        if (timer.active || (timer.lastActive &&
            DateTime.fromISO(timer.lastActive).startOf('day').toLocaleString() ===
             DateTime.utc().startOf('day').toLocaleString())) {
            return true;
        } else {
            return false;
        }
    }
    @action updateFilterTodayTimers = () => {
        this.filterTodayTimersFlag = !this.filterTodayTimersFlag;
        if (this.filterTodayTimersFlag) {
            let idx = this.filteredTimers.findIndex(timer => timer.id === this.selectedTimer.id)
            if (idx === -1) {
                this.rootStore.routerStore.push(`/timers`);
                this.selectedTimer = new ImmutableTimer();
            }
        }
    }
    resetSortAndFilterTimers = async () => {
        this.sortByAlphaNumericToggle = undefined;
        this.filterTodayTimersFlag = false;
        this.matterFilter = null;
        this.clientFilter = null;
        this.applyingFilter = true;
        await this.loadTimers();
        this.applyingFilter = false;
    }
    @computed get filteredTimers(): ImmutableTimer[] {
        let preFilterTimers = this.timers;
        if (this.matterFilter) {
            preFilterTimers = this.matterFilteredTimers;
        } else if (this.clientFilter) {
            preFilterTimers = this.clientFilteredTimers;
        }
        const activeTimer = this.timers.find((t) => !!t.active);

        let filteredtimers = preFilterTimers.filter((timer: ImmutableTimer) => {
            return this.searchWithinObject(timer, this.searchText);
        });
        switch (this.sortByAlphaNumericToggle ) {
            case true:
                filteredtimers =  filteredtimers.sort((a, b) => sortByAlphaNumeric(a.name, b.name))
                break;
            case false:
                filteredtimers =  filteredtimers.sort((a, b) => sortByAlphaNumeric(b.name, a.name))
                break;
            case undefined:
                filteredtimers = filteredtimers.sort((a, b) => {
                    return  DateTime.fromISO(a.lastModified) > DateTime.fromISO(b.lastModified) ? -1 : 1;
                })
                break;
            default:
                break;
        }
        if (this.filterTodayTimersFlag) {
            filteredtimers = filteredtimers.filter((timer) => this.isActiveToday(timer));
        }
        // remove active filter from Filtered
        filteredtimers = filteredtimers.filter(t => !t.active);

        // Always add from initial timers
        if (activeTimer) {
            filteredtimers.unshift(activeTimer);
        }
        return filteredtimers;
    }
    
    updateSegmentNarrative = async (chunk: TimerChunk, descr: string) => {
        chunk.description = descr;
        await this.rootStore.api.Timer.updateChunks([chunk]);
        this.rootStore.snackbarStore.triggerSnackbar('Timer segments updated successfully');
    }
    // Timer filter methods should go here
    @action.bound setClientFilter = async (
        client: Client | null,
        isNull?: boolean,
        fromRecieve?: boolean
    ) => {
        if (isNull || client === undefined) {
            // This is to not make continous calls when value is null
            return;
        }
        if (!fromRecieve) {
            this.applyingFilter = true;
        }
        this.clientFilter = client;
        if (client) {
            this.clientFilteredTimers = await this.rootStore.api.Timer.filterTimersByClient(client.id);
        } else {
            this.clientFilteredTimers = [];
        }
        this.matterFilter = null;
        this.resetSelectedTimer(this.clientFilteredTimers);
        this.applyingFilter = false;
    }
    @action.bound setMatterFilter = async (
        matter: Matter | null,
        isNull?: boolean,
        fromRecieve?: boolean
    ) => {
        if (isNull || matter === undefined) {
            // This is to not make continous calls when value is null
            return;
        }
        if (!fromRecieve) {
            this.applyingFilter = true;
        }
        this.matterFilter = matter;
        if (matter) {
            const client: Client = {
                id: matter.clientId,
                name: matter.clientName,
                number: matter.clientNumber
            }
            this.clientFilter = { ...client }
            this.matterFilteredTimers = await this.rootStore.api.Timer.filterTimersByMatter(matter.id);
        } else {
            if (this.clientFilter) {
                this.matterFilteredTimers = [];
                this.clientFilteredTimers =
                    await this.rootStore.api.Timer.filterTimersByClient(this.clientFilter.id);
            }
        }
        this.resetSelectedTimer(this.matterFilteredTimers);
        this.applyingFilter = false;
    }

    resetSelectedTimer = (timersArr: ImmutableTimer[]) => {
        // Resetting selected timer if it isnt in the timers list
        let idx = timersArr.findIndex(timer => timer.id === this.selectedTimer.id)
        // Checking whether the timer present in the list with idx && checking whether the selectedTimer
        // is active && checking whether the selectedTimer in the right side pane is already empty
        if (idx === -1 && !this.selectedTimer.active &&
            JSON.stringify(this.selectedTimer) !== JSON.stringify(new ImmutableTimer())) {
            this.rootStore.routerStore.push(`/timers`);
            this.selectedTimer = new ImmutableTimer();
            this.playAndStopLoading = '';
        }
    }
    
    // Timer Pop out methods

    @action openTimerPopOut = async () => {
        if (await this.rootStore.canIChangeScope()) {
            if (Platform.isElectron()) {
                const { ipcRenderer } = require('electron');
                ipcRenderer.send('open-timer-popout');
            } else {
                if (!this.externalWindow) {
                    this.externalWindow = window.open('./#/timersPopOut', '_blank', TIMER_POPOUT_WINDOW_SETTINGS);
                    this.externalWindow.focus();
                    this.externalWindow.onbeforeunload = () => {
                        this.closePopOut();
                    }
                }
            }
            this.popOutOpen = true;
        }
    }
    // called from TimerPopOut componentWillMount to load set selectedTimer and popOutOpen
    @action onMountTimerPopOut = () => {
        if (Platform.isElectron()) {
            // set selectedTimer in popup when it is mounted to be used to set playAndStop
            this.tabexClient.emit('selectedTimerLoader');
        }
    }
    async getUnassignedChunks(timer: ImmutableTimer) {
        let chunksToAssign: TimerChunk[] = [];
        let getChunks = (await this.rootStore.api.Timer.getChunks(timer.id!))
            .map(t => t as TimerChunk);
        chunksToAssign = chunksToAssign.concat(getChunks);
        
        let unAssignedChunksOnly = chunksToAssign.filter((ch) => !ch.timeEntryId)
        return unAssignedChunksOnly;
    }
    @action assignSelectedFromPopOut = async () => {
        this.popOutLoader = true;
        await this.tabexClient.emit('setUpdateChunkProps', true)
        await this.tabexClient.emit('mainTimerLoader', true)
        setTimeout(async () => {
            const mesgs: PopoutErrorMessage[] | undefined = await this.assignSelected(this.selectedImmutableTimers);
            this.tabexClient.emit('setUpdateChunkProps', false);
            this.tabexClient.emit('mainTimerLoader', false)
            this.popOutLoader = false;
            if (mesgs && mesgs.length > 0) {
                this.rootStore.timerPopoutErrorDialogStore.open(mesgs);
            }
        }, 4000);
    }
    @action assignSelected = async (selectedImmutableTimers: ImmutableTimer[]) => {
        const features: Features = this.rootStore.appStore.features;
        
        let noCurrentDayTimers: ImmutableTimer[] = [];
        let timeEntryCreatedTimers: ImmutableTimer[] = [];
        let errorMessages: PopoutErrorMessage[] = [];
        
        for (let i = 0; i < selectedImmutableTimers.length; i++) {
            const validTimer = selectedImmutableTimers[i];
            let unAssignedChunksOnly = await this.getUnassignedChunks(validTimer);
            if (unAssignedChunksOnly.length === 0) {
                noCurrentDayTimers.push(validTimer);
                continue ;
            }
            let startTime = '';
            const currentDayChunksOnly = unAssignedChunksOnly.filter((ch) => {
                const date = new Date(ch.endTime);
                const year = date.getFullYear();
                const month = date.getMonth();
                const day = date.getDate();
                const curDate = new Date();
                return (year === curDate.getFullYear() && month === curDate.getMonth() && day === curDate.getDate())
            })
            if (currentDayChunksOnly.length > 0) { // Do not create timeEntries when there aren't any chunks
                startTime = new Date(Math.max.apply(null, currentDayChunksOnly.map((e) => {
                    return new Date(e.startTime);
                }))).toString();

                let chunksMatterId = validTimer.matterId!
                let chunksTemplateId = validTimer.templateId!
                let matter = null;
                if (chunksMatterId) {
                    matter = await this.rootStore.api.Matter.get(chunksMatterId);
                }

                let newEntry = await this.buildEntryFromChunks(startTime,
                    Array.from(currentDayChunksOnly), matter, chunksTemplateId);
                newEntry.duration = (newEntry.duration < 0) ? newEntry.duration * -1 : newEntry.duration;
                // Reference field required for some clients, then assign Timer name as Reference.
                if (features.EpochConfigReferenceRequired && !newEntry.matterId) {
                    newEntry = newEntry.setReference(validTimer.name);
                }
                let vstate = ValidateSave(
                    newEntry,
                    await this.rootStore.api.TimeEntry.getTotalForDateExclusive(newEntry.workDateTime,
                        [newEntry.id!]),
                    this.rootStore.appStore.features,
                    this.rootStore.appStore.getActiveTimeKeeperForDate(
                        DateTime.fromISO(newEntry.workDateTime))
                );
                if (vstate.twentyFourDuration) {
                    errorMessages.push({
                        message: 'Duration cannot exceed 24 hours.',
                        color: 'red'
                    });
                    continue;
                }

                let result = await this.rootStore.api.TimeEntry.associateSegmentsToEntry(newEntry, currentDayChunksOnly);
                // let result = results[0];

                const {
                    TimeEntryApi,
                    TimerChunkApis
                } = result
                if (TimeEntryApi.status.failed) {
                    this.rootStore.snackbarStore.triggerSnackbar(TimeEntryApi.status.message);
                    newEntry.sapStatus = SapStatus.UNSUBMITTED;
                    newEntry = newEntry.clone();
                    return;
                }
                if (TimerChunkApis) {
                    let updated: TimerChunk[] = [];
                    TimerChunkApis.forEach(api => updated.push(api.object))
                    if (Platform.isWeb()) {
                        this.tabexClient.emit('receiveChunks', updated);
                    }
                }
                timeEntryCreatedTimers.push(validTimer);
                continue;
            } else {
                noCurrentDayTimers.push(validTimer);
                continue;
            }
        }

        if (noCurrentDayTimers.length > 0 ) {
            const noCurrentDayTimerNames: string = noCurrentDayTimers.map((t) => t.name).join(', ');
            errorMessages.push({
                message: `'${noCurrentDayTimerNames}' cannot be assigned for today.`,
                color: 'black'
            });
        }
        if (timeEntryCreatedTimers.length > 0 ) {
            const createdTimerNames: string = timeEntryCreatedTimers.map((t) => t.name).join(', ');
            errorMessages.push({
                message: `New Time Entry created successfully for '${createdTimerNames}'.`,
                color: 'green'
            });
        }
        
        // let timers: ImmutableTimer[] = await this.rootStore.api.Timer.getAll();
        // this.recieveTimers(this.selectedImmutableTimers);
        this.rootStore.homeStore.tabexClient.emit('updatedTimersInHomePage');
        this.selectedTimerIds.clear();
        this.setUpdateChunkProps(false);
        
        return errorMessages;
    }
    showAssignSelectedMessages = (msgObj: AssignSelectedMsg) => {
        this.rootStore.snackbarStore.isError = msgObj.errorColor;
        this.rootStore.snackbarStore.triggerSnackbar(msgObj.message);
    }
    @action resetSelected = async () => {
        let chunksToReset: TimerChunk[] = [];
        // TODO Implement backend change for this. Cannot be multiple api calls.
        for (let idx = 0; idx < this.selectedImmutableTimers.length; idx++) {
            let getChunks = (await this.rootStore.api.Timer.getChunks(
                this.selectedImmutableTimers[idx].id!)).map(t => t as TimerChunk);
            chunksToReset = chunksToReset.concat(getChunks);
        }
        let writeChunks = chunksToReset.map((chk) => {
            return {
                ...chk,
                deleted: true
            }
        })

        await this.rootStore.api.Timer.updateChunks(writeChunks, this.selectedImmutableTimers);
        // let timers: ImmutableTimer[] = await this.rootStore.api.Timer.getAll();
        this.selectedImmutableTimers.forEach((t) => {
            t.totalDuration = 0;
            t.convertedDuration = 0;
            t.pendingDuration = 0;
            return t;
        })
        if (Platform.isWeb()) {
            this.tabexClient.emit('receiveTimers', this.selectedImmutableTimers);
        }
        this.rootStore.homeStore.tabexClient.emit('updatedTimersInHomePage');
        this.selectedTimerIds.clear();
    }

    @action toggleSelectAllTimers = async () => {
        this.toggleSelectAll = !this.toggleSelectAll;
        this.timers.filter(t => !t.active).forEach(tmr => {
            if (this.toggleSelectAll) {
                this.selectedTimerIds.add(tmr.id);
            } else {
                this.selectedTimerIds.delete(tmr.id);
            }
        });
    }

    @action deleteSelectedTimers = async () => {
        try {
            const confirmDelete = await this.rootStore.deleteConfirmationDialog.open();
            if (!confirmDelete) { return };
            let trs = this.selectedImmutableTimers.map((t) => {
                t.clone();
                t.deleted = true;
                return t;
            });
            let updatedResults = await this.rootStore.api.Timer.updateTimers(
                trs) as ApiResult<ImmutableTimer>[];
            updatedResults.map((tr) => {
                let timr = tr.object as ImmutableTimer;
                this.selectedTimerIds.delete(timr.id);
            });
            this.rootStore.snackbarStore.triggerSnackbar('Deleted Successfully');
            this.tabexClient.emit('receiveTimers', trs, true);
            
            this.rootStore.homeStore.tabexClient.emit('updatedTimersInHomePage');
        } catch (e) {
            logger.info('Timers, Delete Selected Timers has been Cancelled.\n', e);
            return;
        }
    }

    @computed get selectedImmutableTimers(): ImmutableTimer[] {
        return this.timers.filter(time => this.selectedTimerIds.has(time.id));
    }

    @computed get filteredTimersPopout(): ImmutableTimer[] {
        let filteredtimers = this.timers.filter((timer: ImmutableTimer) => {
            return this.searchWithinObject(timer, this.searchTextInPopOut);
        });
        const lastModifiedOnTop = filteredtimers.sort((a, b) => {
            return  DateTime.fromISO(a.lastModified) > DateTime.fromISO(b.lastModified) ? -1 : 1;
        })
        const activeTimerIdx: number = lastModifiedOnTop.findIndex((t) => !!t.active);
        const activeTimer = lastModifiedOnTop[activeTimerIdx];

        if (activeTimer) {
            lastModifiedOnTop.splice(activeTimerIdx, 1);
            lastModifiedOnTop.unshift(activeTimer);
        }
        
        return lastModifiedOnTop;
    }
    @action onSearchChangePopOut = (input: string) => {
        this.searchTextInPopOut = input;
    }

    @action selectTimerInPopOut = async (timerId: number | undefined) => {
        let timer = this.timers.find(t => t.id === timerId);
        if (timer && timer.active) {
            return;
        }
        if (this.selectedTimerIds.has(timerId)) {
            this.selectedTimerIds.delete(timerId);
        } else {
            this.selectedTimerIds.add(timerId);
        }
    }
    
    popOutWindowReload() {
        if (Platform.isElectron()) {
            const { ipcRenderer } = require('electron');
            ipcRenderer.send('timer-reload');
        } else {
            if (this.externalWindow) {
                this.externalWindow.location.reload();
            }
        }
    }

    newTimerFromPopOut = () => {
        if (Platform.isElectron()) {
            const { ipcRenderer } = require('electron');
            ipcRenderer.send('new-timer');
        }
    }
    
    closePopOut = () => {
        if (Platform.isElectron()) {
            const { ipcRenderer } = require('electron');
            ipcRenderer.send('timer-close');
        } else {
            if (this.externalWindow) {
                this.externalWindow.localStorage.removeItem('objectId')
                this.externalWindow.close();
                this.externalWindow = null;
            }
        }
        this.popOutOpen = false;
    }

    minimize = () => {
        const remote  = require('electron').remote;
        remote.getCurrentWindow().minimize();
    }
    pinToTop = () => {
        const { BrowserWindow } = require('electron').remote
        BrowserWindow.getFocusedWindow().setAlwaysOnTop(true)
        this.popOutOnTop = true;
    }
    unpin = () => {
        const { BrowserWindow } = require('electron').remote
        BrowserWindow.getFocusedWindow().setAlwaysOnTop(false)
        this.popOutOnTop = false;
    }
}