import * as React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { RootStore } from 'store/root.store';
import ConfirmationDialogStore from 'store/confirmationDialog.store';

interface Props {
    dialogTitle?: string;
    isOpen?: boolean;
    onCancel?: () => void;
    onSave?: () => void;
    ConfirmationDialogStore?: ConfirmationDialogStore;
}

@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        ConfirmationDialogStore: rootStore.ConfirmationDialogStore
    };
})
@observer
export default class ConfirmationDialog extends React.Component<Props> {
    render() {
        let {
            // isOpen,
            cancel,
            // save
        } = this.props.ConfirmationDialogStore!;
        return (
            <Dialog
                open={this.props.isOpen!}
                onClose={cancel}
                disableRestoreFocus={true}
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
            >
                <DialogTitle id="unsaved-dialog-title">{this.props.dialogTitle}</DialogTitle>
                <DialogActions>
                    <Button onClick={this.props.onCancel} color="primary">
                        NO
                    </Button>
                    <Button onClick={this.props.onSave} color="primary">
                        YES
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}