import * as React from 'react';
import Button from '@material-ui/core/Button';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
} from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { RootStore } from 'store/root.store';
import MultipleTEConfirmationDialogStore from 'store/multipleTEConfirmation.dialog.store';
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog';

interface Props {
    multipleTEConfirmationDialogStore?: MultipleTEConfirmationDialogStore;
}

@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        multipleTEConfirmationDialogStore: rootStore.multipleTEConfirmationDialog
    };
})
@observer
export default class MultipleTEConfirmationDialog extends React.Component<Props> {
    render() {
        let {
            isOpen,
            cancel,
            save
        } = this.props.multipleTEConfirmationDialogStore!;
        return (        
            <ConfirmationDialog
                dialogTitle="Are you sure you want to cancel time entry for remaining days?"
                isOpen={isOpen}
                onCancel={cancel}
                onSave={save}
            />
        );
    }
}