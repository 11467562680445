import { action, computed, observable } from 'mobx';
import { onOpen, onSave } from '@fulcrumgt/mobx-store-utils';
import TimeEntry from 'api/immutables/ImmutableTimeEntry';
import ImmutableTimeEntry, { SapStatus } from 'api/immutables/ImmutableTimeEntry';
import { DateTime } from 'luxon';
import Template from 'api/immutables/ImmutableTemplate';
import ImmutableTemplate from 'api/immutables/ImmutableTemplate';
import {
    ValidatePost,
    ValidateSave,
    ValidateTemplate,
    ValidationState,
    ValidationTemplateState
} from 'api/immutables/validators';
import { DialogRootStore } from 'store/dialog.root.store';
import { CodeSetFlags, TimeEntryType, LocalStorageWorkLocale } from 'api/types/types'
import { RootStore } from './root.store';
import { debounce } from 'typescript-debounce-decorator';
import { ApiResult } from '../api/util';
import { Platform } from '../util/Platform';
import logger from '../logging/logging';

export default class TimeEntryDialogStore extends DialogRootStore {
    @observable entry: TimeEntry;
    @observable templateName: string = '';
    @observable selectedTemplate?: Template | null;
    @observable createAnotherFlag: boolean = false;
    @observable validationState?: ValidationState;
    @observable durationValidationState?: boolean;
    @observable templateValidationState?: ValidationTemplateState;
    @observable saving: boolean = false;
    @observable disableCreateAnother: boolean | undefined = false;
    @observable narrativeText: string | null = '';

    @computed get minNarrativeLength() {
        return this.rootStore!.appStore!.features!.EpochConfigNarrativesMinimumChars;
    }
    @computed get maxNarrativeLength() {
        return this.rootStore!.appStore!.features!.EpochConfigNarrativesMaximumChars;
    }
    
    createHandler?: (timeEntry: TimeEntry) => void;
    saveHandler?: (results: ApiResult<ImmutableTimeEntry>[]) => void
    
    constructor(rs: RootStore) {
        super(rs);
        this.wrappedSave = this.wrappedSave.bind(this);
        this.wrappedPost = this.wrappedPost.bind(this);
    }
    
    open = (input?: TimeEntry, createHandler?: (timeEntry: TimeEntry) => void, disableFeature?: boolean,
            saveHandler?: (results: ApiResult<ImmutableTimeEntry>[]) => void): Promise<{}> => {
        this.createHandler = createHandler;
        this.disableCreateAnother = disableFeature;
        this.saveHandler = saveHandler;
        return super.open(input);
    }

    @onOpen()
    @action setEntry(entry: TimeEntry) {
        try {
        // entry.timeEntryType = TimeEntryType.NORMAL;
        entry.timeEntryType = (entry.timeEntryType === TimeEntryType.COLLABORATE) ?
            TimeEntryType.COLLABORATE : TimeEntryType.NORMAL;
        this.entry = entry;
        this.narrativeText = entry.narrative;
        if (this.entry.id) {
            this.createAnotherFlag = false;
        }
        const tkId = localStorage.getItem('timeKeeperId')
        const localStorageWorkLocaleArr = JSON.parse(localStorage.getItem('workLocale')!)
        const activeLSWorkLocale = localStorageWorkLocaleArr && localStorageWorkLocaleArr.
                find((x: LocalStorageWorkLocale) => x.tkId === Number(tkId!))
        if (activeLSWorkLocale && !this.entry.id) {
            this.entry.workLocaleId = activeLSWorkLocale.workLocaleId
        }
        this.validationState = undefined;
        this.rootStore.setColloaboratees([]);
        } catch (e) {
            logger.info('Time Entries, Setting Entry failed.\n', e)
        }
    }
    
    @action changeEntry = (entry: TimeEntry, newVState?: ValidationState, durVstate?: boolean) => {
        this.entry = entry;
        this.narrativeText = entry.narrative;
        this.validationState = newVState;
        if (durVstate !== undefined) {
            this.durationValidationState = durVstate;
        }
        this.templateName = (entry.matterId === null) ? '' : this.templateName;
        this.templateValidationState = undefined;
    }
    
    @action setFieldLoaderFn = (value: boolean) => {
        this.saving = value
    }
    
    @action toggleCreateAnotherFlag = () => {
        this.createAnotherFlag = !this.createAnotherFlag;
    }
    @debounce(500, {leading: false})
    @action 
    async wrappedPost () {
        if (this.saving) {
            return;
        }
        this.saving = true;
        try {
            await this.postEntry();
        } finally {
        this.saving = false;
        }
    }
    @debounce(500, {leading: false})
    @action 
    async wrappedSave() {
        if (this.saving) {
            return;
        }
        this.saving = true;
        try {
            await this.saveEntry();
        } finally {
        this.saving = false;
        }
    }
    @action postEntry = async () => {
        try {
            let matterEntryType: string = '';
            let matterStatusDesc: string = '';
            let narrativeMinLength;
            let narrativeMaxLength;
            if (this.entry.matterId) {
                const matter = await this.rootStore.api.Matter.get(this.entry.matterId);
                if (matter) {
                    matterEntryType = matter.entryType;
                    matterStatusDesc = matter.statusDescription;
                    narrativeMinLength = matter.minLength;
                    narrativeMaxLength = matter.maxLength;
                    this.entry.bannedWords = matter.bannedWords;
                    this.entry.blockBillingWords = matter.blockBillingWords;
                }
            }
            const activeTimeKeeper = this.rootStore.appStore.getActiveTimeKeeperForDate(DateTime.fromISO(this.entry.workDateTime));
            // todo 24 hour validation
            let vstate = ValidatePost(
                this.entry,
                await this.getTotalDurationExclusive(this.entry.workDateTime, this.entry.id!),
                matterStatusDesc,
                matterEntryType,
                this.rootStore.appStore.features,
                activeTimeKeeper,
                narrativeMinLength,
                narrativeMaxLength
            );
            let template: ImmutableTemplate | undefined;
            if (this.templateName.trim().length > 0) {
                // TODO validate template
                template = this.entry.createTemplate();
                template.name = this.templateName;
                let templateValidationState = ValidateTemplate(
                    template,
                    await this.rootStore.api.Template.getAllTemplates(), this.maxNarrativeLength);
                if (!templateValidationState.valid) {
                    this.templateValidationState = templateValidationState;
                    return;
                }
            }
            // if (Platform.isElectron()) {
            //     vstate = await this.validateCodeSets(this.entry, vstate);
            // }
            if  (vstate.valid && !this.durationValidationState) {
                this.entry = this.entry.setPosted();
                await this.saveEntry();
                this.rootStore.homeStore.setTimersForDay();
                return;
            }
            if (!vstate.valid) {
                this.validationState = vstate;
            }
        } catch (e) {
            logger.info('Time Entries, Posting Entry failed.\n', e)
        }
    }
    @action validateCodeSets = async(entry: TimeEntry, vstate: ValidationState) => {
        try {
        if (entry.phaseId) {
            let phaseCode = await this.rootStore.api.Code.get(entry.phaseId);
            if (phaseCode && phaseCode.deleted) {
                vstate.missing.phase = true;
            }
        }
        if (entry.taskCodeId) {
            let taskCode = await this.rootStore.api.Code.get(entry.taskCodeId);
            if (taskCode && taskCode.deleted) {
                vstate.missing.task = true;
            }
        }
        if (entry.actCodeId) {
            let actCode = await this.rootStore.api.Code.get(entry.actCodeId);
            if (actCode && actCode.deleted) {
                vstate.missing.activity = true;
            }
        }
        if (this.rootStore.appStore.features.EpochConfigFlatFeeCodesEnabled) {
            if (entry.ffTaskCodeId) {
                let ffTaskCode = await this.rootStore.api.Code.get(entry.ffTaskCodeId);
                if (ffTaskCode && ffTaskCode.deleted) {
                    vstate.missing.ffTask = true;
                }
            }
            if (entry.ffActCodeId) {
                let ffActCode = await this.rootStore.api.Code.get(entry.ffActCodeId);
                if (ffActCode && ffActCode.deleted) {
                    vstate.missing.ffAct = true;
                }
            }
        }
        return vstate;
        } catch (e) {
            logger.info('Time Entries, Validating Code Sets failed.\n', e)
            return vstate;
        }
    }
    @action setWorkDate = (date: DateTime) => {
        let actTk = this.rootStore.appStore.getActiveTimeKeeperForDate(date);
        this.entry = this.entry
        .setWorkDate(date)
        .setOffice(actTk ? actTk.office : undefined);
        if (this.validationState) {
            this.validationState.invalidWorkDate = false;
        }
    }
    
    @action setTemplateName = (name: string) => {
        this.templateValidationState = undefined;
        this.templateName = name;
    }
    
    @action setTemplate = async (t?: Template) => {
        try {
        this.selectedTemplate = t;
        if (t) {
            if (t.matter) {
                const codeSetFlags: CodeSetFlags =
                    await this.rootStore.api.Code.determineCodeSetFields(t.matter.id, this.entry.workDateTime);
                t.isPhaseCode = codeSetFlags.isPhaseCode;
                t.isFfTaskCode = codeSetFlags.isFfTaskCode;
                t.isActCode = codeSetFlags.isActCode;
            }
            this.entry = await this.setTemplateProps(t);
            this.validationState = undefined;
            this.templateValidationState = undefined;
            this.templateName = ''
        } else {
            if (this.entry.narrative !== this.narrativeText) {
                this.entry.narrative = this.narrativeText!.replace(this.entry.narrative!, '').trim();
            }
        }
        } catch (e) {
            logger.info('Time Entries, Setting Templates failed.\n', e)
        }
    }
    setTemplateProps = async (t: Template) => {
        let te: ImmutableTimeEntry = this.entry.clone();
        te = this.entry.loadFromTemplate(t);
        te.narrative  = [te.narrative, this.narrativeText ].join(' ').trim();
        te.selectedCodeSetTemplate = null;
        return te;
    }
    
    async getTotalDurationExclusive (workDate: string, id: number) {
        return await this.rootStore.api.TimeEntry.getTotalForDateExclusive(workDate, [id])
    }

    @action
    saveEntry = async () => {
        try {
        if (this.entry.matterId) {
            const matter = await this.rootStore.api.Matter.get(this.entry.matterId);
            if (matter) {
                this.entry.bannedWords = matter.bannedWords;
                this.entry.blockBillingWords = matter.blockBillingWords;
            }
        }

        const activeTimeKeeper = this.rootStore.appStore.getActiveTimeKeeperForDate(DateTime.fromISO(this.entry.workDateTime));
        
        let vstate = ValidateSave(
            this.entry,
            await this.getTotalDurationExclusive(this.entry.workDateTime, this.entry.id!),
            this.rootStore.appStore.features,
            activeTimeKeeper
        );
        if (Platform.isElectron()) {
            vstate = await this.validateCodeSets(this.entry, vstate);
        }
        if (!vstate.valid) {
            this.validationState = vstate;
            return;
        }
        if (this.durationValidationState) {
            return;
        }
        let template: ImmutableTemplate | undefined;
        if (this.templateName.trim().length > 0) {
            // TODO validate template
            template = this.entry.createTemplate();
            template.name = this.templateName;
            let templateValidationState = ValidateTemplate(
                template,
                 await this.rootStore.api.Template.getAllTemplates(), this.maxNarrativeLength);
            if (!templateValidationState.valid) {
                this.templateValidationState = templateValidationState;
                return;
            }
        }
        if (this.rootStore.collaboratees.length > 0) {
            // if entry has collaborateTks then assign rootStore collaboratees ids to it
            if (this.entry.collaborateTks && this.entry.collaborateTks.length > 0) {
                this.entry.collaborateTks = this.rootStore.collaboratees.map(tk => tk.timeKeeperId).join();
            } else {
                // construct the collaborateInfo object and asign to time entry
                const matterId = this.entry.matterId || undefined;
                const author = {
                    timeKeeperId: activeTimeKeeper!.id,
                    tkName: activeTimeKeeper!.name,
                    matterId
                };
                const collaborators = this.rootStore.collaboratees.map(tk => ({ ...tk, matterId }));
                this.entry.collaborateInfo = JSON.stringify({ author, collaborators });
            }
        }
        let results = await this.rootStore.api.TimeEntry.updateEntries([this.entry]);
        let result = results[0];
        
        if (result.status.failed) {
            this.rootStore.snackbarStore.triggerSnackbar(result.status.message);
            this.entry.sapStatus = SapStatus.UNSUBMITTED;
            this.entry = this.entry.clone();
            return;
        } 

        if (!result.status.failed) {
            this.rootStore.snackbarStore.triggerSnackbar('Saved Successfully');
        } 

        if (template) {
            let templateResult = await this.rootStore.api.Template.saveTemplate(template);
        }
        let entry = Object.assign(new TimeEntry(), JSON.parse(JSON.stringify(results[0].object)));
        if (entry.workLocaleId) {
            let workLocaleArr = JSON.parse(localStorage.getItem('workLocale') || '[]');
            let activeWorkLocaleItem = workLocaleArr.find((x: LocalStorageWorkLocale) => x.tkId === activeTimeKeeper!.timeKeeperId)
            if (activeWorkLocaleItem) {
                // Having the work Locale different so that item should change else it remains the same
                if (entry.workLocaleId !== activeWorkLocaleItem.workLocaleId) {
                    workLocaleArr.find((v: LocalStorageWorkLocale) =>
                     v.tkId === activeTimeKeeper!.timeKeeperId).workLocaleId = entry.workLocaleId;
                    localStorage.setItem('workLocale', JSON.stringify(workLocaleArr));
                }
            } else {
                // If we dont have existing timekeeper in the local storage we will push that to the local Storage variable
                workLocaleArr.push({workLocaleId: entry.workLocaleId, tkId: activeTimeKeeper!.timeKeeperId})
                localStorage.setItem('workLocale', JSON.stringify(workLocaleArr));
            }
        }
        entry.isActCode = this.entry.isActCode;
        entry.isPhaseCode = this.entry.isPhaseCode;
        entry.isFfTaskCode = this.entry.isFfTaskCode;
        
        if (this.saveHandler) {
            this.saveHandler(results)
        }
        
        if (this.createAnotherFlag) {
            // this.rootStore.homeStore.changeEntry(entry);
            if (this.createHandler) {
                this.createHandler(entry);
            }

            this.createAnotherEntry(entry);
        } else {
            this.rootStore.setColloaboratees([]);
            this.resolveAndClose(entry);
        }
        
        // @onSave is not getting called on clicking on Save. had to explicitly call clear function.
        this.clear();
        
        return;
        } catch (e) {
            logger.info('Time Entries, Posting Entry failed.\n', e)
            return
        }
    }

    @action
    createAnotherEntry = (oldEntry: TimeEntry) => {
        let actTk = this.rootStore.appStore.getActiveTimeKeeperForDate(
            DateTime.fromISO(oldEntry.workDateTime));
        let entry = new TimeEntry()
            .setWorkDate(DateTime.fromISO(oldEntry.workDateTime))
            .setOffice(actTk ? actTk.office : undefined)
            .setOfficeName(actTk ? actTk.officeName : undefined)
            .setClient(oldEntry.client)
            .setMatter(oldEntry.matter)
            .setDuration(0)
            .setActionCode(oldEntry.actionCodeObj)
            .setStatus(SapStatus.UNSUBMITTED)
            .setNarrative('')
            .setWorkLocaleId(oldEntry.workLocaleId)
        entry.timeKeeperId = oldEntry.timeKeeperId;
        this.selectedTemplate = undefined;
        this.templateName = '';
        this.changeEntry(entry);
    }
    
    @onSave()
    @action
    clear() {
        this.templateName = '';
        this.selectedTemplate = undefined;
        this.durationValidationState = undefined;
        this.templateValidationState = undefined;
        this.rootStore.homeStore.selectedSegments = [];
        this.rootStore.homeStore.selectedTimerSegments = [];
        this.rootStore.homeStore.validationState.clear();
        this.rootStore.timeEntryStore.validationState.clear();
        this.rootStore.collaboratees = [];
    }
}