import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { RootStore } from 'store/root.store';
import DeleteConfirmationDialogStore from 'store/deleteConfirmationDialog.store';
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog';

interface Props {
    deleteConfirmationDialogStore?: DeleteConfirmationDialogStore;
}

@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        deleteConfirmationDialogStore: rootStore.deleteConfirmationDialog
    };
})
@observer
export default class DeleteConfirmationDialog extends React.Component<Props> {
    render() {
        let {
            isOpen,
            cancel,
            save
        } = this.props.deleteConfirmationDialogStore!;
        return (
            <ConfirmationDialog
                dialogTitle="Confirm Delete"
                isOpen={isOpen}
                onCancel={cancel}
                onSave={save}
            />
        );
    }
}