import { observable, action } from 'mobx';
import { DialogStore, onOpen, onSave } from '@fulcrumgt/mobx-store-utils';
import { TimeKeeperAssignment } from 'api/types/types';
import { DialogRootStore } from 'store/dialog.root.store';

export default class TimekeeperDialogStore extends DialogRootStore {
    @observable selectedTimekeeperId: number;
    @observable entries: TimeKeeperAssignment[] = [];
    @observable selectedEntry: number;
    
    @action setTimeKeeper(id: number) {
        this.selectedTimekeeperId = id;
    }
    
    @onOpen()
    @action setEntries(entries: TimeKeeperAssignment[]) {
        this.entries = entries;
    }
    
    selectEntry(entry: number) {
        this.selectedEntry = entry;
    }
    
    @onSave() 
    saveEntry() {
        localStorage.setItem('timeKeeperId', this.selectedEntry.toString())
        this.rootStore.appStore!.setTimekeeper(this.selectedEntry);
        return this.selectedEntry;
    }
}