import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { RootStore } from 'store/root.store';
import * as Styled from './styled.desktop';
import { CallMerge, Check, Delete, PlayArrow, Save, Send, Stop, Undo } from '@material-ui/icons';
import { Button, Checkbox, CircularProgress, Fab, TextField, Tooltip } from '@material-ui/core';
import TimerStore from 'store/Timer/timer.store';
import TimerChunkItem from 'components/TimerChunkItem/TimerChunkItem';
import RunningTimerChunkItem from 'components/TimerChunkItem/RunningTimerChunkItem';
import { FlexDiv } from 'common/flex';
import { AutoSizer, List } from 'react-virtualized';
import ImmutableTimer from '../../api/immutables/ImmutableTimer';
import AutoCompleteField from 'components/AutoCompleteField/AutoCompleteField';
import { MatterItemFormatter } from 'components/TemplateForm';
import { Features, Matter, TimeKeeperAssignment } from '../../api/types/types';
import { ApiConsumer } from 'common/ApiProvider';
import RootAPI from '../../api/interfaces/RootAPI';
import Template from '../../api/immutables/ImmutableTemplate';
import { FabContainer } from '../Home/styled.desktop';
import { FeaturesConsumer } from 'common/FeaturesProvider';
import { TKConsumer } from 'common/TKProvider';

interface HeaderTimerProps {
    timer: ImmutableTimer,
    onStart: () => void;
    onStop: () => void;
    loadingId?: number;
    includeSubmitted: boolean;
}
interface HeaderTimerState {
    // tslint:disable-next-line:no-any
    interval?: any;
    durTxt: string;
}

export class HeaderTimer extends React.Component<HeaderTimerProps, HeaderTimerState> {
    constructor(props: HeaderTimerProps) {
        super(props);
        this.state = {
            durTxt: this.getTimerDurationTxt(),
            interval: props.timer.active ? setInterval(
                this.timerTick,
                250) : undefined
        }
    }
    getSnapshotBeforeUpdate(prevProps: HeaderTimerProps) {
        if (prevProps.timer.active === this.props.timer.active) {
            return null;
        }
        if (prevProps.timer.active) {
            clearInterval(this.state.interval);
            this.setState({
                interval: undefined
            })
            // prev active, now inactive
        } else {
            this.setState({
                interval: setInterval(
                    this.timerTick,
                    0)
            })
        }
        return null;
    }
    timerTick = () => {
        this.setState({durTxt: this.getTimerDurationTxt()})
    }
    getTimerDurationTxt = () => {
        const timer = this.props.timer;
        let secs = timer.totalDuration || 0;
        if (!this.props.includeSubmitted) {
            secs = secs - (timer.convertedDuration || 0);
        }
        if (timer.active) {
            let da = Math.floor((new Date().getTime() - new Date(timer.startedOn!).getTime()) / 1000);
            secs += da;
        }
        // secs = Math.floor(secs / 1000);
        let hours   = Math.floor(secs / 3600);
        let minutes = Math.floor((secs - (hours * 3600)) / 60);
        let seconds = secs - (hours * 3600) - (minutes * 60);
        let hstring = `${hours}`;
        let sstring = `${seconds}`;
        let mstring = `${minutes}`;
        if (hours   < 10) {hstring   = '0' + hstring; }
        if (minutes < 10) {mstring = '0' + mstring; }
        if (seconds < 10) {sstring = '0' + sstring; }
        return `${hstring}:${mstring}:${sstring}`
    }
    render() {
        const { timer, onStart, onStop, loadingId } = this.props;
        const dtxt = timer.active ? this.state.durTxt : this.getTimerDurationTxt();
        return (
                <TKConsumer>
                    { (tk: TimeKeeperAssignment) =>
                    <Styled.TimerHeaderContainer>
                        <div style={{float: 'left'}}>
                    {!timer.active ?
                        <Tooltip title="Start">
                        <Fab
                            disabled={!!loadingId || !tk.writable}
                            onClick={(e) => {
                                onStart!()
                            }}
                            size="medium"
                            color="primary"
                            aria-label="Start"
                        >
                            <PlayArrow />
                            {(loadingId === timer.id) && <Styled.FabProgress size={55} />}
                        </Fab></Tooltip> :
                        <Tooltip title="Pause">
                        <Fab
                            disabled={!!loadingId || !tk.writable}
                            onClick={(e) => {
                                onStop!()
                            }}
                            size="medium"
                            // color="secondary"
                            style={{backgroundColor: 'rgb(255, 87, 34)', color: 'white'}}
                            aria-label="Stop"
                        >
                            <Stop />
                            {(loadingId === timer.id) && <Styled.FabProgress size={55} />}
                        </Fab></Tooltip>}
                        </div>
                        <Styled.TimerHeaderText>
                            {dtxt}
                        </Styled.TimerHeaderText>
                    </Styled.TimerHeaderContainer>
                    }
                    </TKConsumer>
                    
        );
    }
}

interface Props {
    timerStore?: TimerStore;
    match: {
        params: {
            id: string;
        }
    }
}

@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        timerStore: rootStore.timerStore
    };
})
@observer
export default class TimerFormContainer extends React.Component<Props> {
    componentWillMount() {
        // this.props.templateStore!.resetValidation();
        if (this.props.match.params.id === 'new') {
            // this.props.templateStore!.newTemplate();
        } else {
            this.props.timerStore!.wrappedLoadTimer(Number(this.props.match.params.id));
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.props.timerStore!.wrappedLoadTimer(Number(this.props.match.params.id));
        }
    }
    fetchMatters = (api: RootAPI) => async (searchText: string, features: Features) => {
        let  results: Matter[] = []
        if (features.EpochConfigTrackedMatterClientsEnabled) { 
            return await api.Matter.searchMatters(
                searchText, true
            );
        } else {
            results = await api.Matter.searchMatters(
                searchText, true
            );
            if (results.length === 0) {
                results = await api.Matter.getAvailableMatters(
                    searchText,
                    false
                )
            }
        }
        return results
    }

    render() {
        const {
            selectedTimer,
            sortedChunks,
            startTimer,
            loadingTimer,
            setMatter,
            deleteChunk,
            editChunk,
            toggleSelect,
            setTemplate,
            setName,
            matter,
            template,
            chunkEdit,
            stopTimer,
            dirty,
            selectedChunks,
            showSelectAll,
            selectAllStatus,
            selectAllTimeSegments,
            timerLoading,
            openTimeEntry,
            saveTimer,
            revert,
            clearSelections,
            deleteSelectedChunks,
            entryFromSelectedChunks,
            runningChunkEdit,
            validation,
            originalTimer,
            addTimerNote,
            updateSegmentNarrative,
            shouldUpdateChunkProps,
            setUpdateChunkProps
        } = this.props.timerStore!;
        const includeSubmitted
            = this.props.timerStore!.rootStore.appStore.features.EpochConfigTimeSegmentsSubmittedVisible;
        if (!selectedTimer || timerLoading) {
            return (
                <div style={{textAlign: 'center'}}>
                    <CircularProgress/>
                </div>
            );
        }

        const fetchTemplates = this.props.timerStore!.rootStore.templateStore.fetchTemplatesInAutoComplate;
        
        return (
            <TKConsumer>
                { (tk: TimeKeeperAssignment) =>
            <>
                <Styled.TimerHeader>
                {showSelectAll && <FlexDiv>
                    <Checkbox
                        checked={selectAllStatus}
                        onChange={selectAllTimeSegments}
                        disableRipple={true}
                        disabled={!tk.writable}
                    />
                </FlexDiv>}
                <HeaderTimer
                    timer={selectedTimer}
                    loadingId={loadingTimer}
                    onStart={startTimer(originalTimer)}
                    onStop={stopTimer(selectedTimer.id!)}
                    includeSubmitted={includeSubmitted}
                />
                    <FlexDiv flex={1}>
                        <TextField 
                            style={{width: '97%'}}
                            value={selectedTimer.name} 
                            disabled={selectedTimer.active || !tk.writable} 
                            label="Name" 
                            onChange={(evt: React.ChangeEvent<HTMLInputElement>) => setName(evt.target.value)}
                            fullWidth={true}
                            error={!!validation.name}
                            helperText={validation.name}
                        />
                    </FlexDiv>
                    <FeaturesConsumer>
                        { (features: Features) =>
                        <ApiConsumer>
                            {(api: RootAPI) =>
                                <>
                                    <FlexDiv direction="column" flex={1}>
                                        <AutoCompleteField
                                            width="99%"
                                            label={features.EpochConfigMatterLabel}
                                            fetch={(searchText: string) =>
                                                this.fetchMatters(api)(searchText, features)
                                            }
                                            currentItem={matter}
                                            clearable={true}
                                            onClear={() => setMatter()}
                                            formatItem={MatterItemFormatter}
                                            getItemText={(m: Matter) => `${m.number} - ${m.name}`}
                                            onSelect={setMatter}
                                            disabled={selectedTimer.active || !tk.writable}
                                            tooltip={(m: Matter) => m.description}
                                        />
                                    </FlexDiv>
                                    <FlexDiv direction="column" flex={1}>
                                        <AutoCompleteField
                                            width="98%"
                                            label="Template"
                                            fetch={fetchTemplates}
                                            currentItem={template}
                                            clearable={true}
                                            disabled={selectedTimer.active || !tk.writable}
                                            onClear={() => setTemplate()}
                                            getItemText={(m: Template) => `${m.name}`}
                                            onSelect={setTemplate}
                                        />
                                    </FlexDiv>
                                </>
                            }
                        </ApiConsumer>
                         }
                    </FeaturesConsumer>
                </Styled.TimerHeader>
                
                <FlexDiv direction="column" flex={1}>
                    {
                    selectedTimer.active &&
                    <RunningTimerChunkItem
                        timer={selectedTimer}
                        segmentChange={runningChunkEdit}
                        onAddNote={addTimerNote(selectedTimer!)}
                    />
                    }
                    <div style={{ flex: '1 1 auto' }}>
                        <AutoSizer>
                            {({ width, height }) => {
                                return (
                                <Styled.TimerChunkList>    
                                    <List
                                        height={height}
                                        rowHeight={80}
                                        rowCount={sortedChunks.length}
                                        width={width}
                                        onScroll={() => setUpdateChunkProps(true)}
                                        rowRenderer={({style, key, index}) => {
                                            return (
                                                <div key={sortedChunks[index].id}>
                                                    <TimerChunkItem
                                                        timeEntryOpen={() => 
                                                            openTimeEntry(
                                                                sortedChunks[index].timeEntryId!,
                                                                selectedTimer.id || 0
                                                            )
                                                        }
                                                        segmentChange={chunkEdit(index)}
                                                        toggleSelect={(evt) => {toggleSelect(evt, sortedChunks[index].id!)}}
                                                        selected={selectedChunks.includes(sortedChunks[index].id!)}
                                                        onDelete={deleteChunk(sortedChunks[index])} 
                                                        onEdit={editChunk(sortedChunks[index])}
                                                        segment={sortedChunks[index]!}
                                                        key={sortedChunks[index].id}
                                                        style={style}
                                                        updateSegmentNarrative={updateSegmentNarrative}
                                                        clearSelections={clearSelections}
                                                        shouldUpdate={shouldUpdateChunkProps}
                                                        setChunkPropsUpdate={setUpdateChunkProps}
                                                    />
                                                </div>
                                            );
                                        }}
                                    />
                                </Styled.TimerChunkList>
                                );
                            }}
                        </AutoSizer>
                    </div>
                    
                </FlexDiv>
                <FabContainer onScreen={selectedChunks.length > 0 || dirty}>
                    { dirty && <>
                    <Button
                        onClick={saveTimer}
                        variant="extendedFab"
                        aria-label="Save Timer"
                    >
                        <Save />
                        Save Timer
                    </Button>
                        <Button
                            onClick={revert}
                            variant="extendedFab"
                            color="secondary"
                            aria-label="Revert"
                        >
                            <Undo />
                            Revert
                        </Button>
                    </>
                    }
                    
                    {(selectedChunks.length > 0) && <>
                        <Button
                            onClick={clearSelections}
                            variant="extendedFab"
                            color="secondary"
                            aria-label="Clear"
                        >
                            {/*<Undo />*/}
                            De-Select ({selectedChunks.length})
                        </Button>
                        <Button
                            onClick={entryFromSelectedChunks}
                            variant="extendedFab"
                            aria-label="New Entry"
                        >
                            <Check />
                            New Time Entry ({selectedChunks.length})
                        </Button>
                        <Button
                            onClick={deleteSelectedChunks}
                            variant="extendedFab"
                            aria-label="Delete"
                        >
                            <Delete />
                            Delete ({selectedChunks.length})
                        </Button>
                    </>}
                </FabContainer>
                {/* <TemplateForm 
                    template={selectedTemplate!} 
                    onChange={onTemplateChange}
                    validation={validation}
                />
                <Styled.ButtonsContainer>
                <Button
                    size="small"
                    onClick={() => saveTemplate(selectedTemplate)}
                    style={{marginRight: '10px'}}
                    disabled={!selectedTemplate.dirty}
                >
                    Save
                    <Save style={{marginLeft: '6px', alignItems: 'center'}} />
                </Button>
                <Button
                    size="small"
                    onClick={restoreTemplate}
                    disabled={!selectedTemplate.dirty}
                >
                    Cancel
                    <Cancel style={{marginLeft: '6px', alignItems: 'center'}} />
                </Button>
                </Styled.ButtonsContainer> */}
            </>
            }
            </TKConsumer>
        );
    }
}