import React, { Component } from 'react';
import { Button, Checkbox, Paper, Typography } from '@material-ui/core';
import { RootStore } from 'store/root.store';
import AppStore from 'store/app.store';
import { inject, observer } from 'mobx-react';
import { FlexDiv } from 'common/flex';
import { Platform } from '../../util/Platform';
import logger from '../../logging/logging';
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog';
import ElectronRootImpl from 'api/implementations/electron/Root.impl';
import { TimeCastSegmentI } from 'api/implementations/electron/Dexie';

interface Props {
    rootStore?: RootStore
    appStore?: AppStore
}

interface State {
    shiftKeyPressed: boolean;
    logging: boolean;
    isOpen: boolean;
}

@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        rootStore: rootStore,
        appStore: rootStore.appStore
    };
})

@observer
class GeneralSettings extends Component<Props, State> {
    state: State = {
        shiftKeyPressed: false,
        logging: false,
        isOpen: false
    }
    epochDBFileRef = React.createRef<HTMLInputElement>();
    
    componentDidMount(): void {
        document.addEventListener('keyup', this.keyup);
        document.addEventListener('keydown', this.keydown);
        const enableLogging = localStorage.getItem('enableLogging');
        if (enableLogging) {
            this.setState({
                logging: JSON.parse(enableLogging)
            });
        }
    }

    componentWillUnmount(): void {
        document.removeEventListener('keyup', this.keyup);
        document.removeEventListener('keydown', this.keydown);
    }
    
    exportLocalDB = async () => {
        if (this.props.rootStore!.api.Session.exportLocalDBToJSON) {
            this.props.rootStore!.api.Session.exportLocalDBToJSON!();
            this.setState({
                shiftKeyPressed: false
            })
        }
    }
    cancel = () => {
        this.setState({
            logging: false,
            isOpen: false
        });
    }
    save = () => {
        this.setState({
            logging: true,
            isOpen: false
        });
    }

    doUploadDB = () => {
        this.epochDBFileRef.current!.click();
    };

    handleDBUpload = async (selectorFiles: FileList) => {
        let file: File = selectorFiles[0];
        if (file) {
            await this.props.rootStore!.api.Session.importDB!(file);
        } else {
            logger.error('failed importing db, could not get file from input');
        }
    }

    render() {
        const disableAnimsValue = this.props.appStore!.disableAnimations;
        return (
            <>
            <Paper style={{ padding: '15px' }}>
                <FlexDiv style={{ width: '100%' }} direction="column">
                    <Typography variant="headline">
                        General settings
                    </Typography>
                    <FlexDiv direction={'row'} style={{alignItems: 'center'}}>
                        <Checkbox
                            onChange={(evt, checked) => this.setAnimationsValue(checked)}
                            checked={disableAnimsValue}
                        />
                        <Typography variant={'subheading'}>Disable Animations</Typography>
                    </FlexDiv>
                    <FlexDiv direction={'row'} style={{alignItems: 'center'}}>
                        <Checkbox
                            onChange={(evt, checked) => this.setPassiveLogging(checked)}
                            checked={this.state.logging}
                        />
                        <Typography variant={'subheading'}>Enable logging</Typography>
                    </FlexDiv>

                    {this.state.shiftKeyPressed && Platform.isElectron() && 
                        <FlexDiv direction={'column'} style={{alignItems: 'flex-start'}}>
                            <Button onClick={this.exportLocalDB}>
                                Download Epoch Database
                            </Button>
                        </FlexDiv>
                    }
                    {Platform.isElectron() && process.env.NODE_ENV === 'development' && 
                        <FlexDiv direction={'column'} style={{alignItems: 'flex-start'}}>
                            <input
                                ref={this.epochDBFileRef}
                                style={{ display: 'none' }}
                                accept=".zip"
                                type="file"
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    this.handleDBUpload(event.target.files!);
                                }}
                            />
                            <Button onClick={this.doUploadDB}>
                                Upload Epoch Database
                            </Button>
                        </FlexDiv>
                    }
                   
                </FlexDiv>
            </Paper>
            {this.state.logging &&
            <ConfirmationDialog
                dialogTitle="Are you sure you want to enable logging?"
                isOpen={this.state.isOpen}
                onCancel={this.cancel}
                onSave={this.save}
            />
            }
        </>
        );
    }

    private setPassiveLogging = (val: boolean) => {
        this.setState({
            logging: val,
            isOpen: val
        });
        if (val) {
            logger.silent = false;
        } else {
            logger.silent = true;
        }
        localStorage.setItem('enableLogging', JSON.stringify(val));
    }

    private setAnimationsValue(checked: boolean) {
        const store = this.props.appStore!;
        store.setDisableAnimations(checked);
    }

    private keydown = (e: KeyboardEvent) => {
        if (e.shiftKey) {
            this.setState({ shiftKeyPressed: true })
        }
    }

    private keyup = (e: KeyboardEvent) => {
        // `e.ctrlKey` for keyup events is false for some reason
        // that's why we skip the check

        if (e.keyCode === 16) {
            this.setState({ shiftKeyPressed: false })
        }
    }
}

export default GeneralSettings;