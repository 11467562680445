import { action, observable } from 'mobx';
import { Narrative } from '../api/types/types';
import { onOpen, onSave } from '@fulcrumgt/mobx-store-utils';
import { DialogRootStore } from 'store/dialog.root.store';
import { sortByAlphaNumeric } from '../util/utils';
import Mutex from '../api/util';

export default class NarrativeCodesDialogStore extends DialogRootStore {
    @observable selectedNarrative: Narrative;
    @observable narratives: Narrative[] = [];
    @observable narrative: Narrative;
    @observable currentOffset: number = 0;
    caughtEmAll: boolean = false;
    loadMutex = new Mutex();
    limit: number = 50;
    
    @action setNarrative(narrative: Narrative) {
        this.selectedNarrative = narrative;
    }

    @action resetNarratives () {
        this.currentOffset = 0;
        this.caughtEmAll = false;
        this.narratives = []; 
    }

    searchAvailableNarratives = async (offset?: number, limit?: number) => {
        try {
            return await this.rootStore.api.Narrative.getAllNarratives(offset, limit);
        } catch (e) {
            return [];
        }
    }

    fetchMoreAvailNarratives = async () => {
        if (this.caughtEmAll) {
            return;
        }
        await this.loadMutex.execute(async () => {
            const newElems = await this.searchAvailableNarratives(this.currentOffset, this.limit);
            this.currentOffset = this.currentOffset + newElems.length;
            if (newElems.length === 0) {
                this.caughtEmAll = true;
            }
            this.narratives = this.narratives.concat(newElems);
        })
    }
    
    @onOpen()
    getNarratives() {
        // TODO uncomment this to rest Narrative everytime dialog loaded.
        this.resetNarratives();
        this.fetchMoreAvailNarratives();
    };
    @action setNarratives(narratives: Narrative[]) {
        this.narratives = narratives;
    }
    
    selectNarrative(narrative: Narrative) {
        this.narrative = narrative;
    }
    
    @onSave()
    saveNarrative(narrative: Narrative) {
        return this.narrative;
    }
}