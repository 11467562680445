import { action, computed, observable } from 'mobx';
import { RootStore } from 'store/root.store';
import BaseStore from 'store/base.store';
import { Features, LoginMode, OfflineItems, Progress, TimeKeeperAssignment, User, WeekDays,
     TkOffice, TkOfficeOfficeName } from 'api/types/types';
import { DateTime } from 'luxon';
import { Platform } from '../util/Platform';
import TimecastImpl from '../api/implementations/electron/TimeCast.impl';
import { TIMECAST_IPC_CHANNEL } from 'store/timecastSettings.store';
import { TimeCastSettings } from '../containers/TimeCastSettings/TimeCastSettings';
import fnsLocaleGB from 'date-fns/locale/en-GB';
import fnsLocaleUS from 'date-fns/locale/en-US';
import SessionImpl from '../api/implementations/electron/Session.impl';
import { debounce } from 'typescript-debounce-decorator';
import Mutex from 'api/util';
import logger from '../logging/logging';
import { tabex } from '../api/implementations/web/Session.impl';

const constants = require('../constants.json');

export default class AppStore extends BaseStore {
    /** whether the side bar component is expanded or not */
    @observable sidebarExpanded = true;
    /** whether app is initializing user */
    @observable initializing: boolean = true;
    @observable isServerSet: boolean;
    @observable isFetchingRegistryKeys: boolean = Platform.isElectron();
    @observable authenticated = false;
    /** current user profile */
    @observable user: User;
    @observable timeKeeperAssignments: TimeKeeperAssignment[] = [];
    @observable timeKeeperOffices: TkOffice[] = [];
    @observable features: Features;

    @observable online: boolean = false;
    @observable disableAnimations: boolean = false;
    @observable syncing: boolean = false;
    @observable offlineEntryCount: number = 0;
    @observable tkSearchText: string = '';
    @observable errorText: string = '';

    @observable initProgress: Progress | undefined;
    @observable needsSoftLogin: boolean = false;
    @observable chunkSaveLoader: boolean = false;
    // tslint:disable-next-line:no-any
    @observable allTimeKeepersList: Array<TimeKeeperAssignment> = [];
    @observable currentEnd: number = 0;
    // caughtEmAll: boolean = false;
    loadMutex = new Mutex();
    tabexClient =  tabex.client();

    /* true when the timecast background server is up and running */
    isTimeCastServerRunning: boolean = false;
    /* Functions that should be called ONCE after the timecast background server is ready */
    pendingTimeCastServerHandlers: Function[] = [];
    caughtAll: boolean = false;
    doReSync: boolean = false;
    handlerDestructor: () => void;

    @computed get startOfWeek(): 1 | 2 | 3 | 4 | 5 | 6 | 7 {
        switch (this.features.EpochConfigCalendarFirstDayOfWeek) {
            case WeekDays.SUN:
                return 7;
                break;
            case WeekDays.MON:
                return 1;
                break;
            case WeekDays.TUE:
                return 2;
                break;
            case WeekDays.WED:
                return 3;
                break;
            case WeekDays.THURS:
                return 4
                break;
            case WeekDays.FRI:
                return 5;
                break;
            case WeekDays.SAT:
                return 6;
                break;
            default:
                return 1;
        }
    }

    constructor(root: RootStore) {
        super(root)
        this.tabexClient.on('setPendingCount', this.setPendingCountTabex);
        this.isServerSet = this.rootStore.api.Session.serverSet
        if (this.isServerSet) {
            if (window.location.search.includes('code')) {
                let search = window.location.search;
                let parameters: { code?: string } = {};
                search
                    .substr(1)
                    .split('&')
                    .forEach((entry: string) => {
                        let eq = entry.indexOf('=');
                        if (eq >= 0) {
                            parameters[
                                decodeURIComponent(entry.slice(0, eq))
                                ] = decodeURIComponent(entry.slice(eq + 1));
                        }
                    });
                if (parameters.code && parameters.code !== 'fail') {
                    try {
                        this.rootStore.api.Session.ssoLogin(parameters.code).then(() => {
                            this.initialize(true);
                        }).catch(() => {
                            logger.error('SSO login failed')
                            this.initialize(false);
                            if (window.history.replaceState) {
                                window.history.replaceState(
                                    '',
                                    'Epoch',
                                    window.location.origin + window.location.pathname
                                )
                            }
                        });
                    } catch (e) {
                        logger.error(e);
                        this.initializing = false;
                        throw e;
                    }
                }
            } else {
                const tkId = localStorage.getItem('timeKeeperId');
                if (tkId) {
                    this.initializeHandler();
                    this.initialize(false);
                    return;
                } else {
                    this.autoSSOLogin();
                }
            }
        } else {
            /*
                Listen for server URL from external sources (such as the Windows registry) on Electron.
            */
            if (Platform.isElectron()) {
                const ipcRenderer = require('electron').ipcRenderer

                ipcRenderer.on(constants.ipcEvents.DONE_LOADING_REGISTRY_KEYS, () => {
                    this.isFetchingRegistryKeys = false;
                });

                ipcRenderer.once(constants.ipcEvents.USE_SERVER_URL, (event: unknown, serverURL: unknown) => {
                    if (serverURL && typeof serverURL === 'string' && serverURL.length > 0) {
                        this.connectToServerUrl(serverURL)
                    }
                });
            } else {
                let regServerUrl = window.location.origin;
                if (regServerUrl) {
                    this.connectToServerUrl(regServerUrl);
                }
            }
        }
        this.rootStore.api.Session.setProgressListener(this.setLoginProgress)
    }
    @action setLoginProgress = (message: string, precent?: number) => {
        this.initProgress = {
            message: message,
            percent: precent
        }

        if (precent && precent > 100) {
            // clear message in 1 second
            setTimeout(() => {
                this.initProgress = undefined
            }, 1000)
        }
    }
    initializeHandler = () => {
        this.handlerDestructor = this.rootStore.api.Session.pendingItemsReciever(this.setOfflineEntries);
        this.rootStore.api.Session.updatedTKsReciever(this.setUpdatedTimeKeepers);
    }
    @action.bound
    initialize = async (shouldOpenEULADialog: boolean, tkSwitch?: boolean) => {
        this.setLoginProgress('Initializing...', 0);
        this.initializing = true;
        this.authenticated = false;
        this.setLoginProgress('Loading features...', 5);
        if (shouldOpenEULADialog) {
            this.features = await this.rootStore.api.Session.getFeatures();
        } else {
            this.features = await this.rootStore.api.Session.getFeaturesApi();
        }
        
        if (this.features.EpochConfigNarrativesMaximumChars === null ||
            this.features.EpochConfigNarrativesMaximumChars === undefined) {
            this.features.EpochConfigNarrativesMaximumChars = 999999999999999;
        }
        // Initialize timecast if the feature is enabled
        if (Platform.isElectron() && this.features.EpochConfigTimeCastEnabled) {
            this.setLoginProgress('Initializing TimeCast...', 10);
            (this.rootStore.api.TimeCast as TimecastImpl).listenForIPCEvents();

            const ipcRenderer = require('electron').ipcRenderer;
            /* before requesting the server to start, listen for the server ready event */
            ipcRenderer.on(constants.ipcEvents.DONE_STARTING_TIMECAST_SERVER, () => {
                // this.isTimeCastServerRunning = true;
                const handlers = [...this.pendingTimeCastServerHandlers];
                this.pendingTimeCastServerHandlers = [];
                handlers.forEach(h => h());
            });
            /* signal the server to start */
            ipcRenderer.send(`${TIMECAST_IPC_CHANNEL}-initialize`);
            this.isTimeCastServerRunning = true;
        }

        try {
            this.setLoginProgress('Initializing session...', 15);
            let result = await this.rootStore.api.Session.initialize();

            if (result === 'needs-soft-login') {
                this.setLoginProgress('Session has expired.', 100);
                this.needsSoftLogin = true
                this.initializing = false
                return;
            }
            const authenticated = (result === true)
            if (this.features.EpochConfigTimeCastEnabled && authenticated) {
                this.setLoginProgress('Fetching TimeCast settings...');
                const settings = await this.rootStore.api.Settings.getByKey(constants.timecast.settingKey);
                const timeCastSettings = TimeCastSettings.fromSetting(settings);
                try {
                    if (!tkSwitch) {
                        if (timeCastSettings.shouldConnectAutomatically()) {
                            this.connectToTimeCast();
                        } else {
                            if (shouldOpenEULADialog) {
                                this.purgeActivities();
                            }
                            this.disconnectFromTimeCast(Platform.isWeb() ? 'server' : 'desktop');
                        }
                    }
                } catch (e) {
                   // logger.error('Error connecting/disconnecting to TimeCast', e);
                }
            }

            const animValue = localStorage.getItem('animations') || 'false';
            this.disableAnimations = JSON.parse(animValue);
            if (authenticated) {
                this.rootStore.timeEntryStore.loadAllWorkLocales();
                this.rootStore.customDictionaryStore.loadDictionaries();
            }
            let openEula =  (
                !JSON.parse(localStorage.getItem('hideEula')!)
                || new Date(this.rootStore.eulaDialogStore.lastModified) > new Date(JSON.parse(localStorage.getItem('EulaAcceptedDate')!)));
            if (authenticated && shouldOpenEULADialog && openEula) {
                this.rootStore.eulaDialogStore.open()
                    .then((eulaAcceptance: boolean) => {
                        this.authenticated = true;
                        this.getTimekeeper(authenticated);
                        if (window.history.replaceState) {
                            window.history.replaceState(
                                '',
                                'Epoch',
                                window.location.origin + window.location.pathname
                            )
                        }
                        this.rootStore.routerStore.push('/home')
                    }).catch((e) => {
                        logger.info('Error resolving EULA dialog', e);
                        this.logOut();
                    });
            } else {
                this.getTimekeeper(authenticated);
                if (this.currentTimekeeper && !this.currentTimekeeper.writable) {
                    this.rootStore.timerStore.closePopOut();
                }
            }
        } catch (err) {
            logger.error('Error initializing: ', err);
            this.initializing = false;
        }
    };
    @action.bound setOnline(online: boolean) {
        this.online = online;
    }
    @action.bound
    async setUpdatedTimeKeepers(tks: TimeKeeperAssignment[]) {
        if (this.rootStore.api.Session.currentTimeKeeper) {
            const currentTK = tks.find(tk => tk.timeKeeperId === this.rootStore.api.Session.currentTimeKeeper);
            if (!currentTK) {
                await this.logOut();
            }
            tks = tks.map((tk) => {
                if (tk.timeKeeperId === this.rootStore.api.Session.currentTimeKeeper) {
                    tk.deleted = false;
                }
                return tk;
            });
        }
        this.timeKeeperAssignments = tks;
    }
    setOfflineEntries = (entries: OfflineItems | undefined) => {
        this.tabexClient.emit('setPendingCount', entries, true);
    }
    setPendingCountTabex = (entries: OfflineItems | undefined) => {
        if (entries) {
            this.rootStore.pendingItemsStore.setPendingTimeEntries(entries.TimeEntries);
            this.rootStore.pendingItemsStore.setPendingTimers(entries.Timers);
            this.rootStore.pendingItemsStore.setPendingTemplates(entries.Templates);
            this.rootStore.pendingItemsStore.setPendingTimerChunks(entries.TimerChunks);

            this.offlineEntryCount = entries.TimeEntries.length + entries.Timers.length + entries.Templates.length;
        } else {
            this.offlineEntryCount = 0;
        }
    }
    // TimeKeeper stuff
    @computed get uniqueTimeKeepers(): TimeKeeperAssignment[] {
        let tks = this.timeKeeperAssignments.slice().sort((a, b) => {
            return new Date(b.startDate).getTime() - new Date(a.startDate).getTime();
        })
            .reduce((acc, cur) => {
                let ex = acc.filter(a => a.timeKeeperId === cur.timeKeeperId);
                if (ex.length > 0) {
                    return acc;
                }
                return acc.concat([cur]);
            } , [] as TimeKeeperAssignment[]);
        tks = tks.filter(tk => !tk.deleted)
        return tks;
    }
    getActiveTimeKeeperForDate = (date: DateTime): TimeKeeperAssignment | undefined => {
        let tkId = this.rootStore.api.Session.currentTimeKeeper;
        let validTkas = this.timeKeeperAssignments.filter(tka => {
            let startDate = DateTime.fromISO(tka.startDate);
            let endDate = DateTime.fromISO(tka.endDate);
            return date >= startDate && date <= endDate;
        }).filter((tk) => {
            return tk.timeKeeperId === tkId;
        });
        return validTkas[0]!;
    }
    getActiveTKOfficesForDate = (date: DateTime): TkOfficeOfficeName[] | undefined => {
        let tka = this.getActiveTimeKeeperForDate(date);
        let tkId = this.rootStore.api.Session.currentTimeKeeper;
        let validTkos = this.timeKeeperOffices.filter(tko => {
            let startDate = DateTime.fromISO(tko.startDate);
            let endDate = DateTime.fromISO(tko.endDate);
            return date >= startDate && date <= endDate && tko.timekeeperId === tkId;
        }).map(x => {
            return {
                office: x.office,
                officeName: x.officeName
            }
        });
        if (tka) {
            validTkos.push({
                office: tka!.office,
                officeName: tka!.officeName
            })
        }
        return validTkos;
    }
    getActiveTimeKeeper = (): TimeKeeperAssignment | undefined => {
        let tkId = this.rootStore.api.Session.currentTimeKeeper;
        let validTkas = this.timeKeeperAssignments.filter((tk) => {
            return tk.timeKeeperId === tkId;
        });
        return validTkas[0]!;
    }
    getTkOffices = async () => {
        let tkId = this.rootStore.api.Session.currentTimeKeeper;
        this.timeKeeperOffices = await this.rootStore.api.Session.getTimeKeeperOffices(tkId!)
        this.timeKeeperOffices = this.timeKeeperOffices.filter(x => !x.deleted)
    }
    getTimekeeper = async (authenticated: boolean) => {
        if (authenticated) {
            this.user = await this.rootStore.api.Session.me();
            if (this.user.valid === false) {
                await this.logOut();
                this.rootStore.snackbarStore.triggerSnackbar('Timekeeper is not valid');
                return;
            }
            this.timeKeeperAssignments = await this.rootStore.api.Session.getTimekeeperAssignments();
            let tkId = this.rootStore.api.Session.currentTimeKeeper;
            if (!tkId) {
                if (this.timeKeeperAssignments.length === 0) {
                    if (this.user && this.user.name) {
                        alert(`No timekeepers assigned to User ${this.user.name}.`);
                    }
                    this.logOut();
                    return;
                }
                if (this.uniqueTimeKeepers.length === 1) {
                    this.rootStore.api.Session.setTimeKeeper(this.uniqueTimeKeepers[0].timeKeeperId);
                    this.authenticated = true;
                } else {
                    this.rootStore.timekeeperDialogStore.open(this.uniqueTimeKeepers.slice())
                        .then((timeKeeper: number[]) => {
                                this.rootStore.api.Session.setTimeKeeper(timeKeeper[0]);
                                this.initializing = false;
                                this.authenticated = true;
                            }
                        ).catch(() => {
                        this.logOut();
                    });
                    return;
                }
            } else {
                if (!this.currentTimekeeper) {
                    await this.logOut();
                    return;
                }
                // this.rootStore.api.Session.currentTimeKeeper = Number(tkId);
                this.authenticated = true;
            }
            // Calling tk offices after we get the current timekeeper
            this.getTkOffices();
        }
        this.initializing = false;
    }
    @action setTimekeeper = async (tkid: number) => {
        const allowed = await this.rootStore.canIChangeScope();
        if (allowed) {
            this.rootStore.api.Session.setTimeKeeper(tkid);
            if (location.hash.lastIndexOf('templates') > -1) {
                this.rootStore.routerStore.push('/templates/new');
            }
            if (location.hash.lastIndexOf('timers') > -1) {
                this.rootStore.routerStore.push('/timers');
            }
            this.rootStore.timerStore.popOutWindowReload();
            await this.initialize(false, true);
        }
    };
    @action.bound setTkSearchText(s: string) {
        this.tkSearchText = s;
    }
    @action getAllTimeKeepers = async (date: string, matterId?: number | null) => {
        this.allTimeKeepersList = await this.rootStore.api.Session.getAllTimeKeepersList(
            this.tkSearchText, 0, 50, date, matterId
        );
        this.currentEnd = this.allTimeKeepersList.length;
        // should be reset to false as it is not reset at all in the code.
        this.caughtAll = false;
    }
    @debounce(1000, {leading: false})
    async debouncedGetTimekeepersList(date: string, matterId?: number | null) {
        await this.getAllTimeKeepers(date, matterId);
    }
    @computed get filteredAllTimekeepersList(): TimeKeeperAssignment[] {
        return this.allTimeKeepersList;
    }
    fetchMoreTimeKeepers = async (date: string, matterId?: number | null) => {
        if (this.caughtAll) {
            return;
        }
        await this.loadMutex.execute(async () => {
            let startOffset = this.currentEnd;
            let newElems = await this.rootStore.api.Session.getAllTimeKeepersList(
                this.tkSearchText, startOffset, 50, date, matterId
            );
            this.currentEnd = this.currentEnd + newElems.length;
            if (newElems.length === 0) { this.caughtAll = true; }
            this.allTimeKeepersList = this.allTimeKeepersList.concat(newElems);
        })
    }
    @computed get currentTimekeeper(): TimeKeeperAssignment {
        return this.uniqueTimeKeepers
            .filter(tk => tk.timeKeeperId === this.rootStore.api.Session.currentTimeKeeper)[0]
    }
    @computed get filteredTimeKeepers(): TimeKeeperAssignment[] {
        let query = this.tkSearchText.toLowerCase()
        let filteredTks = this.uniqueTimeKeepers.filter((tk) =>
            tk.name.toLowerCase().includes(query) ||
            tk.office.toLowerCase().includes(query) ||
            tk.officeName.toLowerCase().includes(query)
        )
        let arr =  filteredTks.slice().sort((a, b) => {
            return a.name.localeCompare(b.name);
        });
        return arr;
    }
    // TimeKeeper stuff ends here
    @action connectToServerUrl = async (serverUrl: string) => {
        if (this.isServerSet) {
            // Don't set the server twice.
            return;
        }
        const finalAPIUrl = `${serverUrl}/api`;
        const baseUrl = serverUrl.replace('https://', '').replace('http://', '');
        const wsUrl = `${serverUrl.includes('https') ? 'wss://' : 'ws://'}${baseUrl}/ws`;

        try {
            await this.rootStore.api.Session.setServer(finalAPIUrl, wsUrl);
        } catch (e) {
            // getFeatures api failed
            logger.error('Error connecting server Url', e);
            this.errorText = 'Please enter a valid URL';
            localStorage.removeItem('serverUrl');
            localStorage.removeItem('wsUrl');
            return;
        }

        await this.rootStore.snackbarStore.triggerSnackbar('Connected to server.');
        this.isServerSet = true;
        this.autoSSOLogin();
    }
    autoSSOLogin = async () => {
        const features: Features = JSON.parse(localStorage.getItem('features') || '');
        if (features) {
            if (features.EpochConfigLoginMode === LoginMode.EAGER_SSO_FALLBACK) {
                try {
                    await this.rootStore!.api.Session.silentSSOLogin(features);
                    await this.initialize(true);
                    return;
                } catch (e) {
                    logger.error(e);
                    this.initializing = false;
                    await this.rootStore.snackbarStore.triggerSnackbar('SSO Login failed');
                    // this.initialize(false);
                    return ;
                }
            } else {
                this.initialize(false);
            }
        } else {
            this.initialize(false);
        }
    }
    @action logOut = async () => {
        if (await this.rootStore.canIChangeScope()) {
            await this.rootStore.homeStore.reset();
            await this.rootStore.api.Session.logOut();
            this.authenticated = false;
            this.initializing = false;
            this.tkSearchText = '';
            this.rootStore.timeEntryStore.resetTimeEntryStore();
            this.rootStore.timerStore.closePopOut();
            const socket = new WebSocket(constants.timecast.windowsWebSocketUrl);
            // If TC is not running, then don't restart TC on logout
            setTimeout(() => {
                if (socket &&
                    (socket.readyState !== WebSocket.CLOSED && socket.readyState !== WebSocket.CLOSING)) {
                    this.disconnectFromTimeCast(Platform.isWeb() ? 'server' : 'desktop');
                    this.rootStore.timecastSettingsStore.updateConnectionState();
                }
            }, 3000)
        }
    }
    doHardLogout = async () => {
        if ( this.doReSync ) {
            await this.reSync();
            this.doReSync = false;
        } else {
            localStorage.removeItem('user');
            this.needsSoftLogin = false;
            this.logOut();
        }
    }
    @action attemptHardLogout = async () => {
        const sessionAPI: SessionImpl = this.rootStore!.api.Session as SessionImpl;
        const offlineEntries = await sessionAPI.getAllOfflineEntries();
        if (
            offlineEntries && (
                offlineEntries.Templates.length > 0 ||
                offlineEntries.TimeEntries.length > 0 ||
                offlineEntries.TimerChunks.size > 0 ||
                offlineEntries.Timers.length > 0
            )
        ) {
            this.rootStore!.hardLogoutStore.message = 'log out'
            this.rootStore!.hardLogoutStore.open();
        } else {
            this.logOut();
        }
    };
    @action synchronize = async () => {
        if (await this.rootStore.canIChangeScope()) {
            this.syncing = true;

            try {
                await this.rootStore.api.Session.sync();
                this.rootStore.snackbarStore.triggerSnackbar('Sync successful');
            } catch (e) {
                this.rootStore.snackbarStore.triggerSnackbar('Sync error');
            } finally {
                this.syncing = false;
            }
        }
    }

    @action reSync = async () => {
        await this.rootStore.api.Session.getAll();
        this.rootStore.snackbarStore.triggerSnackbar('Re-Sync successful');
    }

    @action reSynchronize = async () => {
        if (await this.rootStore.canIChangeScope()) {
            this.syncing = true;

            try {
                const sessionAPI: SessionImpl = this.rootStore!.api.Session as SessionImpl;
                const offlineEntries = await sessionAPI.getAllOfflineEntries();
                if ( offlineEntries && this.online && (
                        offlineEntries.Templates.length > 0 ||
                        offlineEntries.TimeEntries.length > 0 ||
                        offlineEntries.TimerChunks.size > 0 ||
                        offlineEntries.Timers.length > 0
                    )) {
                        this.doReSync = true;
                        this.rootStore!.hardLogoutStore.message = 're-sync'
                        this.rootStore!.hardLogoutStore.open();
                    } else {
                        await this.reSync();
                    }
            } catch (e) {
                this.rootStore.snackbarStore.triggerSnackbar('Re-Sync error');
            } finally {
                this.syncing = false;
            }
        }
    }
    @action.bound setDisableAnimations(val: boolean) {
        this.disableAnimations = val;
        localStorage.setItem('animations', JSON.stringify(val));
    }
    @action setChunkSaveLoader(val: boolean) {
        this.chunkSaveLoader = val;
    }
    /**
     * set value of sideBarExpanded
     * @param  {boolean} state
     */
    @action setSideBarExpanded(state: boolean) {
        this.sidebarExpanded = state;
    }
    @computed get pickersUtilLocale() {
        if (this.features && this.features.EpochConfigCalendarFirstDayOfWeek === WeekDays.MON) {
            return fnsLocaleGB
         } else {
             return fnsLocaleUS
         }
    }
    getFeaturesApi = async () => {
        if (this.rootStore.api.Session.getFeaturesApi) {
            await this.rootStore.api.Session.getFeaturesApi();
            setTimeout(() => {
                window.location.reload();
            }, 1000)
            
        }
    }
    /*===============================================
       TimeCast background process communication
     ================================================*/
    onTimeCastServerReady = (onTimeCastServerReady: () => void) => {
        if (this.isTimeCastServerRunning) {
            onTimeCastServerReady()
        } else {
            this.pendingTimeCastServerHandlers.push(onTimeCastServerReady);
            onTimeCastServerReady()
        }
    }
    purgeActivities = () => {
        this.onTimeCastServerReady(this._purgeActivities)
    }
    _purgeActivities = () => {
        this.callProtocol(
            constants.timecast.PROTOCOL,
            'purge-activity'
        )
    }
    updateTimeCast = () => {
        this.onTimeCastServerReady(this._updateTimeCast)
    }
    _updateTimeCast = () => {
        this.callProtocol(
            constants.timecast.PROTOCOL,
            'update-config'
        );
    }
    connectToTimeCast = () => {
        this.onTimeCastServerReady(this._connectToTimeCast);
    }
    _connectToTimeCast = () => {
        const url = Platform.isElectron() ? `http://localhost:${constants.timecast.backgroundServerPort}` :
            `${window.location.protocol}//${window.location.host}/api/`;
        const displayName = localStorage.getItem('displayName') || '';
        const userId = localStorage.getItem('userId') || '-1';
        const sessionId = localStorage.getItem('sessionId') || '-1';
        const authToken = this.getAuthToken();
        const refreshToken = this.getRefreshToken();
        this.callProtocol(
            constants.timecast.PROTOCOL,
            Platform.isElectron() ? 'connect-desktop' : 'connect-server',
            {
                url,
                userId,
                sessionId,
                displayName,
                authToken,
                refreshToken
            }
        );
    }
    disconnectFromTimeCast = (platform: 'server' | 'desktop') => {
        this.onTimeCastServerReady(this._disconnectFromTimeCast(platform));
    }
    _disconnectFromTimeCast = (platform: 'server' | 'desktop') => () => {
        // TODO: switch to websocket connection
        if (Platform.isElectron()) {
            const { ipcRenderer } = require('electron')
            ipcRenderer.sendSync(TIMECAST_IPC_CHANNEL, JSON.stringify({
                action: 'auth',
                context: {
                    authToken: null,
                    refreshToken: null,
                }
            }));
        }
        this.callProtocol(
            constants.timecast.PROTOCOL,
            platform === 'desktop' ? 'disconnect-desktop' : 'disconnect-server'
        )
    }
    /*===============================================
       URI Protocol helper functions
     ================================================*/
    private callProtocol = (protocol: string, command: string, queryParameters?: Record<string, string>) => {
        // Build query
        let queryString = '';
        if (typeof queryParameters !== 'undefined') {
            Object.keys(queryParameters).forEach(function (key: string) {
                queryString += queryString.length > 0 ? '&' : '';
                queryString += `${key}=${encodeURI(queryParameters[key])}`;
            });
        }

        if (Platform.isElectron()) {
            try {
            const { ipcRenderer } = require('electron')
            ipcRenderer.sendSync(TIMECAST_IPC_CHANNEL, JSON.stringify({
                action: 'auth',
                context: {
                    authToken: this.getAuthToken(),
                    refreshToken: this.getRefreshToken()
                }
            }));
            ipcRenderer.send(TIMECAST_IPC_CHANNEL, JSON.stringify({
                action: 'protocol-message',
                context: {
                    protocol,
                    command,
                    queryString
                }
            }));
            } catch (e) {
                logger.error('Error connecting/disconnecting to TimeCast', e);
            }
        } else {
            try {
                const url = queryString.length > 0
                    ? `${protocol}:${command}?${queryString}`
                    : `${protocol}:${command}`;

                const socket = new WebSocket(constants.timecast.windowsWebSocketUrl);
                socket.onerror = (e: Event) => {
                    // do nothing
                };
                socket.onopen = (e: Event) => {
                    socket.send(JSON.stringify({ url }));
                    socket.close();
                }
            } catch (e) {
                logger.error('Error connecting/disconnecting to TimeCast', e);
                throw e
            }
        }
    };
    private getAuthToken(): string {
        return localStorage.getItem('token') || '';
    }
    private getRefreshToken(): string {
        return localStorage.getItem('refreshToken') || '';
    }
    get ssoFallback(): boolean {
        if (this.features) {
            return this.features.EpochConfigLoginMode === LoginMode.EAGER_SSO_FALLBACK;
        }
        return false;
    }
}
// TODO: Auth flow here
