import { observable, action } from 'mobx';
import { DialogStore, onOpen, onSave } from '@fulcrumgt/mobx-store-utils';
import { DialogRootStore } from 'store/dialog.root.store';
import { RootStore } from 'store/root.store';
import { DateTime } from 'luxon';
import { EULA } from 'api/types/types';
export default class EULADialogStore extends DialogRootStore {
    @observable eulaAcceptance: boolean;
    @observable eulaText: string = '';
    lastModified: Date;
    eula: EULA;
    constructor(rs: RootStore) {
        super(rs);
    }
    open = (): Promise<{}> => {
        return super.open();
    }
    @onOpen()
    @action async getEula() {
        if (this.eula === undefined) {
            this.eula = await this.rootStore.api.Session.getEulaText();
        }
        this.eulaText = this.eula.eulaText;
        this.lastModified = this.eula.lastModified;
    }
    @action setEulaAcceptance = (eulaAcceptance: boolean, hideEula: boolean) => {
        this.eulaAcceptance = eulaAcceptance;
        const today = DateTime.local().setZone('local');
        localStorage.setItem('hideEula', JSON.stringify(hideEula));
        localStorage.setItem('EulaAcceptedDate',  JSON.stringify(today));
    }

    @onSave()
    saveEulaAcceptance(eulaAcceptance: boolean) {
        return this.eulaAcceptance;
    }
}